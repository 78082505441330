import React from "react";
import { Link } from "react-router-dom";

// import bg1 from '../assets/images/bg/about.jpg'
import bg1 from "../assets/images/about/img2.webp";
import { ctaData } from "../data/data";
import { ctaData1 } from "../data/data";
import Navbar from "../components/navbar";
import AboutImage from "../components/aboutImage";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { medicalServices, doctorData } from "../data/data";

import {
  FiArrowRight,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  FiTwitter,
} from "../assets/icons/vander";
import CtaTwo from "../components/cta/ctaTwo";
import bg2 from "../assets/images/bg/home-1-background-1.jpg";
import heroImage from "../assets/images/about/AboutHero.png";

import shareBg from "../assets/images/home/sharedWrapp-bg.png";
import CashOnly from "../assets/images/about/Cash-Only.jpg";
import SharedExperience from "../assets/images/about/SharedExperience.jpg";
import circleLogo from "../assets/images/home/vector-logo.png";

import TransBGImage from "../assets/images/services/Transformational-Bg.jpg";
import MissionImage from "../assets/images/about/our-mission.jpg";

export default function AboutUs() {
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />

      <section
        className="heroWrapperAbout"
        style={{ backgroundImage: `url(${bg2})`, backgroundSize: "cover" }}
      >
        {/**<div className="bg-overlay bg-overlay-custom"></div>**/}
        <div className="container">
          <div className="row t-lg-0 heroRow">
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 leftHeroCol">
              <div className="heading-title">
                {/* <img src={logoIcon} height="50" alt=""/> */}
                <h2 className="heroTitle mt-0 "> About Thrive Abundance</h2>
                <p className="heroSubtitle">
                  At Thrive Abundance, we are committed to enriching the lives
                  of our aging population through compassionate, evidence-based
                  adult day services. Our mission is to foster independence and
                  promote well-being. We understand that each individual has
                  unique needs, and our programs are designed to meet those
                  needs with care and precision. Our services are rooted in
                  research, ensuring that every activity and support plan we
                  offer delivers measurable benefits to our participants.
                </p>
              </div>
            </div>
            <div style={{ position: "relative" }} className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
              <img className="heroImageImg" alt="" src={heroImage} />
            </div>
          </div>
        </div>
      </section>

      <section className="homeAboutRow AboutPageBlurb">
        {/** <FeatureOne/> **/}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <img className="MisiionImg" src={MissionImage} alt="Our Mission" style={{maxWidth: "100%"}}/>
              </div>
            <div className="col-lg-7 col-md-7 mt-1 aboutRightCol">
              <div className="ms-lg-4">
                <div className="section-title">
                  <h5 className="aboutsubtitle">Is to help you thrive</h5>
                  <h2 className="aboutTitle">Our Mission</h2>
                  <p className="aboutPara-desc">
                    Our mission is to ensure that high-quality mental health
                    care is accessible to everyone, no matter where they live.
                    From major cities to rural areas, our licensed therapists
                    are here to provide support when it's needed most.Who We Are
                    and Why We're HereAt Thrive Abundance, we believe that
                    mental health care should be a fundamental right, not a
                    privilege. Our team of mental health professionals is
                    dedicated to helping individuals, couples, and families
                    overcome life's challenges and achieve lasting positive
                    change.
                  </p>
                  {/* <p className="aboutPara-desc">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words.</p> */}
                  <h3 className="aboutMidTitle">We are committed to:</h3>
                  <ul className="listPoints">
                    <li>
                      Providing empathetic, expert care tailored to your
                      specific needs.
                    </li>
                    <li>
                      Creating a safe, non-judgmental space for healing and
                      growth.{" "}
                    </li>
                    <li>Offering accessible and convenient therapy options.</li>
                    <li>Fostering a sense of community and mutual support.</li>
                    <li>
                      Ensuring high-quality, affordable mental health services
                      for all.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="AboutOverlayWrapp">
      <div
        className="TransformationalWrapp"
        style={{
          backgroundImage: `url(${TransBGImage})`,
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4
                  className="sectionTitle"
                  style={{ color: "#FFF", marginBottom: "30px" }}
                >
                  Our Commitment To You
                </h4>
                <p className="OverlayParaText">
                  At Thrive Abundance, your well-being is our top priority. We
                  are committed to providing the highest quality care and
                  support to help you navigate life's challenges and achieve
                  your mental health goals. We believe in the power of empathy,
                  expertise, and community to transform lives.
                </p>
                <p className="OverlayParaText">
                  Contact Us Today to learn more about our services and how we
                  can support you on your journey to well-being. Together, we
                  can help you thrive
                </p>
                <div className="aboutBtn">
                  <Link to="/contact" className="primaryBtn">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>

      <section
        className="sharedWrapp"
        style={{ backgroundImage: `url(${shareBg})`, backgroundSize: "cover" }}
      >
        {/** <FeatureOne/> **/}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 sharedRowLeft">
              <div className="section-title">
                <h2 className="aboutTitle">Our Occupational Therapists</h2>
                <p className="aboutPara-desc">
                  Our occupational therapists provide specialized care to help
                  clients improve their daily living skills and achieve greater
                  independence. They work closely with clients to develop
                  personalized rehabilitation plans that address both physical
                  and mental health needs.
                </p>
              </div>
            </div>
            <div
              Class="col-lg-6 col-md-6 mt-0"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <img className="shareRightImg" src={SharedExperience} />
            </div>
          </div>
        </div>
        <div className="container onlinePortWrapp">
          <div className="rotateLogo">
            <img
              alt="Rotating"
              className="rotating-image"
              width="200"
              src={circleLogo}
            />
          </div>
          <div className="row align-items-center">
            <div
              Class="col-lg-6 col-md-6 col-sm-6"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <img className="shareLeftImg" src={CashOnly} />
            </div>

            <div className="col-lg-6 col-md-6 mt-1 aboutRightCol">
              <div className="ms-lg-4">
                <div className="section-title">
                  <h2 className="aboutTitle">Our Therapists</h2>
                  <p className="aboutPara-desc">
                    Our licensed therapists are highly qualified and deeply
                    empathetic professionals who specialize in various
                    therapeutic approaches. They are dedicated to creating safe,
                    supportive environments where you can explore your thoughts
                    and feelings and work towards your mental health goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTop />
    </>
  );
}
