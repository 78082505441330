import { RiEyeFill, FaHome, MdBedroomChild, FaUserFriends, HiMiniUserGroup, AiOutlineInteraction, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, } from '../assets/icons/vander'

// import dr1 from '../assets/images/doctors/01.jpg'
import dr1 from '../assets/images/doctors/MainDr.webp'
import dr2 from '../assets/images/doctors/02.jpg'
import dr3 from '../assets/images/doctors/03.jpg'
import dr4 from '../assets/images/doctors/04.jpg'
import dr5 from '../assets/images/doctors/05.jpg'
import dr6 from '../assets/images/doctors/06.jpg'
import dr7 from '../assets/images/doctors/07.jpg'
import dr8 from '../assets/images/doctors/08.jpg'
import dr9 from '../assets/images/doctors/09.jpg'
import dr10 from '../assets/images/doctors/10.jpg'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'
import client9 from '../assets/images/client/09.jpg'
import client10 from '../assets/images/client/10.jpg'

import blog1 from '../assets/images/blog/dr01.jpg'
import blog2 from '../assets/images/blog/dr03.jpg'
import blog3 from '../assets/images/blog/dr04.jpg'
import blog4 from '../assets/images/blog/dr07.jpg'
import blog5 from '../assets/images/blog/dr06.jpg'
import blog6 from '../assets/images/blog/dr12.jpg'
import blog7 from '../assets/images/blog/dr02.jpg'
import blog8 from '../assets/images/blog/dr11.jpg'
import blog9 from '../assets/images/blog/dr10.jpg'
// import blog6 from '../assets/images/blog/06.jpg'
// import blog7 from '../assets/images/blog/07.jpg'
// import blog8 from '../assets/images/blog/08.jpg'
// import blog9 from '../assets/images/blog/09.jpg'

import partners1 from '../assets/images/client/amazon.png'
import partners2 from '../assets/images/client/google.png'
import partners3 from '../assets/images/client/lenovo.png'
import partners4 from '../assets/images/client/paypal.png'
import partners5 from '../assets/images/client/shopify.png'
import partners6 from '../assets/images/client/spotify.png'

import payment1 from '../assets/images/payment/mastercard.png'
import payment2 from '../assets/images/payment/discover.png'
import payment3 from '../assets/images/payment/rupay.png'
import payment4 from '../assets/images/payment/american.png'

import category1 from '../assets/images/pharmacy/skin.jpg'
import category2 from '../assets/images/pharmacy/sexual.jpg'
import category3 from '../assets/images/pharmacy/weight.jpg'
import category4 from '../assets/images/pharmacy/pain.jpg'
import category5 from '../assets/images/pharmacy/heart.jpg'
import category6 from '../assets/images/pharmacy/cough.jpg'
import category7 from '../assets/images/pharmacy/diabetes.jpg'
import category8 from '../assets/images/pharmacy/cancer.jpg'

import product1 from '../assets/images/pharmacy/shop/thermometer.jpg'
import product2 from '../assets/images/pharmacy/shop/stethoscope.jpg'
import product3 from '../assets/images/pharmacy/shop/pulse-oximeter.jpg'
import product4 from '../assets/images/pharmacy/shop/medicine.jpg'
import product5 from '../assets/images/pharmacy/shop/smoking-habit.jpg'
import product6 from '../assets/images/pharmacy/shop/sanitizer.jpg'
import product7 from '../assets/images/pharmacy/shop/nicotex.jpg'
import product8 from '../assets/images/pharmacy/shop/medigrip.jpg'
import product9 from '../assets/images/pharmacy/shop/masks.jpg'
import product10 from '../assets/images/pharmacy/shop/handwash.jpg'
import product11 from '../assets/images/pharmacy/shop/herbal-care.jpg'
import product12 from '../assets/images/pharmacy/shop/medical-equptment.jpg'
import product13 from '../assets/images/pharmacy/shop/praganews.jpg'
import product14 from '../assets/images/pharmacy/shop/ashwagandha.jpg'
import product15 from '../assets/images/pharmacy/shop/beby-products.jpg'
import product16 from '../assets/images/pharmacy/shop/kidney-tray.jpg'
import product17 from '../assets/images/pharmacy/shop/diabend.jpg'
import product18 from '../assets/images/pharmacy/shop/facewash.jpg'

export const medicalServices = [
    {
        icon: RiPsychotherapyFill,
        title: 'Individual Therapy',
        desc: 'Individual therapy provides a personalized and confidential environment where clients can address their personal concerns, mental health issues, and emotional well-being. Whether you\'re struggling with anxiety, depression, trauma, or navigating life\'s transitions, our skilled therapists work with you to explore solutions, gain insights, and develop coping strategies tailored to your needs.',
        link: '/individual-therapy'
    },
    {
        // icon:RiEyeFill,
        icon: FaUserFriends,
        title: 'Couple Therapy',
        desc: 'Couple therapy helps partners work through conflicts, improve communication, and strengthen their relationship. Whether you\'re dealing with trust issues, intimacy concerns, or navigating life changes together, our therapists provide a neutral space to explore and resolve challenges. Through guided sessions, you\’ll learn healthier ways to relate to each other and create a more fulfilling partnership.',
        link: '/couple-therapy'
    },
    {
        // icon:RiCapsuleFill,
        icon: AiOutlineInteraction,
        title: 'Family Therapy',
        desc: 'Family Therapy focuses on helping individuals develop stronger interpersonal skills and improve their ability to connect with others. This service is beneficial for people who experience social anxiety, difficulty making friends, or trouble in social settings. Our therapists work with clients to build confidence, enhance communication, and navigate social situations with greater ease.',
        link: '/family-therapy'
    },
    {
        // icon:RiMicroscopeFill,
        icon: MdBedroomChild,
        title: 'Group Therapy',
        desc: 'Our Group Therapy services offer a safe and supportive living environment for individuals who need therapeutic care in a residential setting. This service includes both therapy and housing, ensuring that clients receive continuous support while working through their mental health challenges. With a focus on comfort, structure, and well-being, this service provides the stability needed for recovery.',
        link: '/group-therapy'
    },
    {
        // icon:RiStethoscopeFill,
        icon: HiMiniUserGroup,
        title: 'Social Interaction',
        desc: 'Social Interaction therapy offers individuals the opportunity to share experiences, learn from others, and find strength in a supportive group environment. Led by a licensed therapist, group therapy can help with issues such as anxiety, depression, and trauma, while fostering a sense of community. Clients gain valuable insights from the shared experiences of others, contributing to their healing process.',
        link: '/social-interaction'
    },
    {
        // icon:RiPulseFill,
        icon: FaHome,
        title: 'Residential Treatment',
        desc: 'Residential Treatment therapy brings therapy sessions directly into the comfort and privacy of your own home. This service is ideal for individuals who may have mobility issues, prefer the convenience of home, or feel more at ease in their familiar surroundings. Whether you need individual therapy, family support, or help with specific mental health concerns, our therapists offer flexible, personalized care at home.For the online booking option:',
        link: '/room-and-board'
    },
    {
        icon: RiEmpathizeFill,
        title: 'Psychiatric Medication',
        desc: 'Psychiatric medication brings therapy sessions directly into the comfort and privacy of your own home. This service is ideal for individuals who may have mobility issues, prefer the convenience of home, or feel more at ease in their familiar surroundings. Whether you need individual therapy, family support, or help with specific mental health concerns, our therapists offer flexible, personalized care at home.For the online booking option:',
        link: '/psychiatric-medication'
    },
    // {
    //     icon:RiMindMap,
    //     title:'Neurology',
    //     desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    // },
    // {
    //     icon:RiMentalHealthLine,
    //     title:'Dermatologists',
    //     desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    // },
    // {
    //     icon:RiAppleLine,
    //     title:'Nutritionists',
    //     desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    // },
    // {
    //     icon:RiInfraredThermometerLine,
    //     title:'Physical Therapists',
    //     desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    // },
]

export const doctorData = [
    {
        image: dr1,
        name: 'Kyle Hill',
        speciality: 'Therapist',
        location: '63, PG Shustoke, UK',
        time: 'Mon: 8:00AM - 8:00PM',
        charges: '$ 75 USD / Visit'
    },
    // {
    //     image: dr2,
    //     name: 'Cristino Murphy',
    //     speciality: 'M.B.B.S, Gynecologist',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
    // {
    //     image: dr3,
    //     name: 'Alia Reddy',
    //     speciality: 'M.B.B.S, Psychotherapist',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
    // {
    //     image: dr4,
    //     name: 'Toni Kovar',
    //     speciality: 'M.B.B.S, Orthopedic',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
    // {
    //     image: dr5,
    //     name: 'Jessica McFarlane',
    //     speciality: 'M.B.B.S, Dentist',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
    // {
    //     image: dr6,
    //     name: 'Elsie Sherman',
    //     speciality: 'Elsie Sherman',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
    // {
    //     image: dr7,
    //     name: 'Bertha Magers',
    //     speciality: 'M.B.B.S, Urologist',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
    // {
    //     image: dr8,
    //     name: 'Louis Batey',
    //     speciality: 'M.B.B.S, Neurologist',
    //     location: '63, PG Shustoke, UK',
    //     time: 'Mon: 2:00PM - 6:00PM',
    //     charges: '$ 75 USD / Visit' 
    // },
]

export const counterData = [
    {
        target: '99',
        symbol: '%',
        titleOne: 'Positive feedback',
        titleTwo: 'From Therapists'
    },
    {
        target: '25',
        symbol: '+',
        titleOne: 'Experienced Clinics',
        titleTwo: 'High Qualified'
    },
    {
        target: '1251',
        symbol: '+',
        titleOne: 'Questions & Answers',
        titleTwo: 'Your Questions'
    },
]

export const patientsData = [
    {
        image: client1,
        name: '- Thomas Israel',
        title: 'C.E.O',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "',
        desc2: '" It seems that only melodies in order to have a ready-made text to sing with the melody of the originalthe Lorem Ipsum, which is said to have originated century. "'
    },
    {
        image: client2,
        name: '- Carl Oliver',
        title: 'P.A',
        desc: '" The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewers attention from the layout. "',
        desc2: '" The advantage of its writing melodies in order to have a ready-made text to sing with the melody and the to itself or distract the viewers attention from the layout. "'
    },
    {
        image: client3,
        name: '- Barbara McIntosh',
        title: 'M.D',
        desc: '" There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories. "',
        desc2: '" There is now an in order to have a ready-made text to sing with the melody alternatives to the classic Lorem Ipsum texts are amusing. "'
    },
    {
        image: client4,
        name: '- Christa Smith',
        title: 'Manager',
        desc: '" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word consectetur he could find "',
        desc2: '" According to most sources in order to have a ready-made text to sing with the melody the origin of the text by compiling all the instances. "'
    },
    {
        image: client5,
        name: '- Dean Tolle',
        title: 'Developer',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "',
        desc2: '" It seems that only in order to have a ready-made text to sing with the melody Lorem Ipsum, which is said to have originated 16th century. "'
    },
    {
        image: client6,
        name: '- Jill Webb',
        title: 'Designer',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "',
        desc2: '" It seems that only lyrics when writing melodies in order to have a ready-made text to sing with the melody of time certain letters were added or deleted at the text. "'
    },
    {
        image: client7,
        name: '- Howard Tanner',
        title: 'C.E.O',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "',
        desc2: '" It seems that only in order to have a ready-made text to sing with the melody Lorem Ipsum, which is said to have originated 16th century. "'
    },
    {
        image: client8,
        name: '- Wendy Filson',
        title: 'Designer',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "',
        desc2: '" It seems that only lyrics when writing melodies in order to have a ready-made text to sing with the melody of time certain letters were added or deleted at the text. "'
    },
]

export const blogData = [
    {
        id: 1,
        image: blog1,
        title: 'Easily connect to Therapist and make a treatment',
        like: '33',
        comment: '08',
        date: '13th Sep 2023',
        time: '5 min read'
    },
    {
        id: 2,
        image: blog2,
        title: 'Lockdowns lead to fewer people seeking medical care',
        like: '33',
        comment: '08',
        date: '29th Nov 2023',
        time: '5 min read'
    },
    {
        id: 3,
        image: blog3,
        title: 'Medicine research course for Therapist',
        like: '33',
        comment: '08',
        date: '29th Dec 2023',
        time: '5 min read'
    },
    {
        id: 4,
        image: blog4,
        title: 'Comparing Nitrogen And Mechanical Freezers',
        like: '33',
        comment: '08',
        date: '13th March 2023',
        time: '5 min read'
    },
    {
        id: 5,
        image: blog5,
        title: 'It Is Very Important To Wear Proper Clothing',
        like: '33',
        comment: '08',
        date: '5th May 2023',
        time: '5 min read'
    },
    {
        id: 6,
        image: blog6,
        title: 'Hollowed-Out Faces More Cuts Amid Virus',
        like: '33',
        comment: '08',
        date: '19th June 2023',
        time: '5 min read'
    },
    {
        id: 7,
        image: blog7,
        title: 'A Researcher Is Research On Coronavirus In Lab',
        like: '33',
        comment: '08',
        date: '20th June 2023',
        time: '5 min read'
    },
    {
        id: 8,
        image: blog8,
        title: 'Using Spectroscopy To Assess Food Quality',
        like: '33',
        comment: '08',
        date: '31st Aug 2023',
        time: '5 min read'
    },
    {
        id: 9,
        image: blog9,
        title: 'You Have To Wash Your Hands For 20 Seconds',
        like: '33',
        comment: '08',
        date: '1th Sep 2023',
        time: '5 min read'
    },
]

export const partners = [partners1, partners2, partners3, partners4, partners5, partners6]

export const category = [
    {
        icon: GrUserPolice,
        title: 'Doctors',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiCapsuleLine,
        title: 'Clinics',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiMicroscopeLine,
        title: 'Labs',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: TbAmbulance,
        title: 'Emergency',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiShieldCrossLine,
        title: 'Insurance',
        desc: 'Due to its wide spread use as filler text'
    },
]
export const ctaData = [
    {
        icon: RiBriefcase4Line,
        title: 'Our Mission',
        desc: 'Our mission is to ensure that high-quality mental health care is accessible to everyone, no matter where they live. From major cities to rural areas, our licensed therapists are here to provide support when it’s needed most.Who We Are and Why We’re HereAt Thrive Abundance, we believe that mental health care should be a fundamental right, not a privilege. Our team of mental health professionals is dedicated to helping individuals, couples, and families overcome life’s challenges and achieve lasting positive change.',
        title1: 'Why We’re in This Field -',
        desc1: 'A Focus on Quality Care -   We’re committed to providing high-quality, evidence-based mental health care that’s tailored to each client’s unique needs.',
        desc8: 'A Mission of Impact -   We are driven by the desire to make a real difference in the lives of those we serve by promoting mental wellness, resilience, and self-empowerment.',
        desc2: 'Addressing Unmet Needs -   We understand that many communities lack access to affordable, convenient, and culturally competent mental health care. ',
        desc7: 'These Markets We’ve chosen to expand into these 10 states and fill that gap because they represent largest, most diverse, and most in-need mental health markets in the country.',
        desc3: 'High Population Density -   States like California, Texas, New York, and Florida have large populations with significant demand for mental health services.',
        desc4: 'Rising Mental Health Needs -   The COVID-19 pandemic has increased the prevalence of anxiety, depression, and other mental health challenges in these regions.',
        desc5: 'Limited Access to Care -   Many individuals in rural or underserved areas lack access to qualified mental health professionals.',
        desc6: 'Market Demand for Specialized Services: In states like Michigan, Illinois, and North Carolina, there is increasing demand for family therapy, couples counseling, case management, and evidence-based treatment modalities like EMDR (Eye Movement Desensitization and Reprocessing).'
    },
    // {
    //     icon: FiAirplay,
    //     title: 'Our Vision',
    //     desc: 'Our top priority is your well-being. We\'re providing the highest quality care and support to help you navigate life’s challenges and achieve your mental health goals. We believe in the power of empathy, expertise, and community to transform lives'
    // },
    // {
    //     icon: MdOutlineFlip,
    //     title: 'Who We Are ?',
    //     desc: 'At Thrive Abundance, we\'re enriching the lives of our aging population through evidence-based adult day services. Our mission is to foster independence and promote well-being. Our services are rooted in research.'
    // },
]
export const ctaData1 = [
    {
        icon: FiAirplay,
        title: 'Our Vision',
        desc: 'Our top priority is your well-being. We\'re providing the highest quality care and support to help you navigate life’s challenges and achieve your mental health goals. We believe in the power of empathy, expertise, and community to transform lives'
    },
    {
        icon: MdOutlineFlip,
        title: 'Who We Are ?',
        desc: 'At Thrive Abundance, we\'re enriching the lives of our aging population through evidence-based adult day services. Our mission is to foster independence and promote well-being. Our services are rooted in research.'
    },
]
export const accordionData = [
    {
        id: 1,
        title: 'How does it work ?',
        desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 2,
        title: 'Do I need a designer to use Thrives ?',
        desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 3,
        title: 'What do I need to do to start selling ?',
        desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 4,
        title: 'What happens when I receive an order ?',
        desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
]
export const contactData = [
    {
        icon: RiStethoscopeFill,
        title: 'Success Of Treatment',
        desc: 'The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century.'
    },
    {
        icon: RiMicroscopeLine,
        title: 'Modern Technology',
        desc: 'The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century.'
    },
    {
        icon: GrUserPolice,
        title: 'Certified Therapist',
        desc: 'The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century.'
    },
]
export const latestAppointment = [
    {
        image: client1,
        name: 'Calvin Carlo',
        date: 'Booking on 13th Sep, 2023'
    },
    {
        image: client2,
        name: 'Joya Khan',
        date: 'Booking on 29th Nov, 2023'
    },
    {
        image: client3,
        name: 'Amelia Muli',
        date: 'Booking on 29th Dec, 2023'
    },
    {
        image: client4,
        name: 'Nik Ronaldo',
        date: 'Booking on 13th March, 2023'
    },
    {
        image: client5,
        name: 'Crista Joseph',
        date: 'Booking on 5th May, 2023'
    },
]

export const upcomingAppointment = [
    {
        image: client6,
        name: 'Cristino Murphy',
        date: 'Booking on 19th June, 2023'
    },
    {
        image: client7,
        name: 'Cristino Murphy',
        date: 'Booking on 20th June, 2023'
    },
    {
        image: client8,
        name: 'Alex Dirio',
        date: 'Booking on 31st Aug, 2023'
    },
    {
        image: client9,
        name: 'Vrunda Koli',
        date: 'Booking on 1st Sep, 2023'
    },
    {
        image: client10,
        name: 'Aisha Low',
        date: 'Booking on 5th May, 2023'
    },
]

export const adminFeature = [
    {
        icon: PiTelegramLogoBold,
        title: 'New Messages',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiMailStarLine,
        title: 'Latest Proposals',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiHourglassLine,
        title: 'Package Expiry',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiHeartLine,
        title: 'Saved Items',
        desc: 'Due to its wide spread use as filler text'
    }
]

export const appointmentData = [
    {
        id: 1,
        clientImage: client1,
        clientName: 'Howard Tanner',
        age: '25',
        gender: 'Male',
        department: 'Cardiology',
        date: '13th Sep 2023',
        time: '11:00AM',
        drImage: dr1,
        drName: 'Dr. Calvin Carlo',
        fees: '$50/Patient'
    },
    {
        id: 2,
        clientImage: client2,
        clientName: 'Wendy Filson',
        age: '28',
        gender: 'Female',
        department: 'Gynecology',
        date: '29th Nov 2023',
        time: '11:00AM',
        drImage: dr1,
        drName: 'Dr. Cristino Murphy',
        fees: '$50/Patient'
    },
    {
        id: 3,
        clientImage: client3,
        clientName: 'Faye Bridger',
        age: '28',
        gender: 'Female',
        department: 'Psychotherapy',
        date: '29th Dec 2023',
        time: '02:30PM',
        drImage: dr1,
        drName: 'Dr. Alia Reddy',
        fees: '$50/Patient'
    },
    {
        id: 4,
        clientImage: client4,
        clientName: 'Ronald Curtis',
        age: '25',
        gender: 'Male',
        department: 'Orthopedic',
        date: '13th March 2023',
        time: '01:00PM',
        drImage: dr1,
        drName: 'Dr. Toni Kovar',
        fees: '$50/Patient'
    },
    {
        id: 5,
        clientImage: client5,
        clientName: 'Melissa Hibner',
        age: '28',
        gender: 'Female',
        department: 'Dental',
        date: '5th May 2023',
        time: '10:00AM',
        drImage: dr1,
        drName: 'Dr. Jessica McFarlane',
        fees: '$50/Patient'
    },
    {
        id: 6,
        clientImage: client6,
        clientName: 'Randall Case',
        age: '25',
        gender: 'Male',
        department: 'Orthopedic',
        date: '19th June 2023',
        time: '09:00AM',
        drImage: dr1,
        drName: 'Dr. Toni Kovar',
        fees: '$50/Patient'
    },
    {
        id: 7,
        clientImage: client7,
        clientName: 'Jerry Morena',
        age: '25',
        gender: 'Male',
        department: 'Dentist',
        date: '20th June 2023',
        time: '02:00PM',
        drImage: dr1,
        drName: 'Dr. Jessica McFarlane',
        fees: '$50/Patient'
    },
    {
        id: 8,
        clientImage: client8,
        clientName: 'Lester McNally',
        age: '25',
        gender: 'Male',
        department: 'Gastrology',
        date: '31st Aug 2023',
        time: '01:00PM',
        drImage: dr1,
        drName: 'Dr. Elsie Sherman',
        fees: '$50/Patient'
    },
    {
        id: 9,
        clientImage: client9,
        clientName: 'Christopher Burrell',
        age: '28',
        gender: 'Female',
        department: 'Urology',
        date: '1st Sep 2023',
        time: '01:00PM',
        drImage: dr1,
        drName: 'Dr. Bertha Magers',
        fees: '$50/Patient'
    },
    {
        id: 10,
        clientImage: client10,
        clientName: 'Mary Skeens',
        age: '28',
        gender: 'Female',
        department: 'Neurology',
        date: '13th Sep 2023',
        time: '11:00AM',
        drImage: dr1,
        drName: 'Dr. Louis Batey',
        fees: '$50/Patient'
    },
]
export const invoiceData = [
    {
        id: '#d01',
        image: client1,
        name: 'Howard Tanner',
        phone: '(+12)85-4521-7568',
        amount: '$253',
        date: '13th Sep 2023',
        status: 'Unpaid'
    },
    {
        id: '#d02',
        image: client2,
        name: 'Wendy Filson',
        phone: '(+12)85-4521-7568',
        amount: '$482',
        date: '29th Nov 2023',
        status: 'Paid'
    },
    {
        id: '#d03',
        image: client3,
        name: 'Faye Bridger',
        phone: '(+12)85-4521-7568',
        amount: '$546',
        date: '29th Dec 2023',
        status: 'Unpaid'
    },
    {
        id: '#d04',
        image: client4,
        name: 'Ronald Curtis',
        phone: '(+12)85-4521-7568',
        amount: '$154',
        date: '13th March 2023',
        status: 'Unpaid'
    },
    {
        id: '#d05',
        image: client5,
        name: 'Melissa Hibner',
        phone: '(+12)85-4521-7568',
        amount: '$458',
        date: '5th May 2023',
        status: 'Paid'
    },
    {
        id: '#d06',
        image: client6,
        name: 'Randall Case',
        phone: '(+12)85-4521-7568',
        amount: '$548',
        date: '19th June 2023',
        status: 'Paid'
    },
    {
        id: '#d07',
        image: client7,
        name: 'Jerry Morena',
        phone: '(+12)85-4521-7568',
        amount: '$658',
        date: '20th June 2023',
        status: 'Unpaid'
    },
    {
        id: '#d08',
        image: client8,
        name: 'Lester McNally',
        phone: '(+12)85-4521-7568',
        amount: '$457',
        date: '31st Aug 2023',
        status: 'Unpaid'
    },
    {
        id: '#d09',
        image: client9,
        name: 'Christopher Burrell',
        phone: '(+12)85-4521-7568',
        amount: '$586',
        date: '1th Sep 2023',
        status: 'Paid'
    },
    {
        id: '#d10',
        image: client10,
        name: 'Mary Skeens',
        phone: '(+12)85-4521-7568',
        amount: '$325',
        date: '13th Sep 2023',
        status: 'Unpaid'
    },
]
export const mailData = [
    {
        name: 'Amy Lucier',
        desc: 'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date: '13th Sep 2023'
    },
    {
        name: 'Tameika Whittle',
        desc: 'The wise man therefore always holds in these matters to this principle of selection.',
        date: '29th Nov 2023'
    },
    {
        name: 'Barbara Bayne',
        desc: 'We denounce with righteous',
        date: '29th Dec 2023'
    },
    {
        name: 'Nita Griffin',
        desc: 'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date: '13th March 2023'
    },
    {
        name: 'Marc Flythe',
        desc: 'The wise man therefore always holds in these matters to this principle of selection.',
        date: '5th May 2023'
    },
    {
        name: 'Elaine Hannah',
        desc: 'pain avoided.',
        date: '19th June 2023'
    },
    {
        name: 'Abraham Edwards',
        desc: 'We denounce with righteous',
        date: '20th June 2023'
    },
    {
        name: 'Allie Smith',
        desc: 'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date: '31st Aug 2023'
    },
    {
        name: 'Eleanor Cisco',
        desc: 'The wise man therefore always holds in these matters to this principle of selection.',
        date: '1st Sep 2023'
    },
    {
        name: 'Troy Turk',
        desc: 'pain avoided.',
        date: '13th Sep 2023'
    },
    {
        name: 'Tyron Elliott',
        desc: 'We denounce with righteous',
        date: '29th Nov 2023'
    },
    {
        name: 'Betty Cook',
        desc: 'We denounce with righteous',
        date: '29th Dec 2023'
    },
    {
        name: 'Doris Morrison',
        desc: 'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date: '13th March 2023'
    },
    {
        name: 'Chad Potter',
        desc: 'The wise man therefore always holds in these matters to this principle of selection.',
        date: '5th May 2023'
    },
]
export const experienceData = [
    {
        time: '2016',
        title: 'Bachelors Arts and Science',
        name: 'Western Michigan University'
    },
    {
        time: '2020',
        title: 'Masters in Social Work',
        name:  'Wayne State University'
    },
    { 
        time: '7 Years',
        name: 'LLMSW',
        title:'Clinical Social Work/Therapist'
    },
]
export const patientsData2 = [
    {
        id: '#d0001',
        image: client1,
        name: 'Howard Tanner',
        gender: 'Male',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0002',
        image: client2,
        name: 'Wendy Filson',
        gender: 'Female',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0003',
        image: client3,
        name: 'Faye Bridger',
        gender: 'Female',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0004',
        image: client4,
        name: 'Ronald Curtis',
        gender: 'Male',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0005',
        image: client5,
        name: 'Melissa Hibner',
        gender: 'Female',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0006',
        image: client6,
        name: 'Randall Case',
        gender: 'Male',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0007',
        image: client7,
        name: 'Jerry Morena',
        gender: 'Male',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0008',
        image: client8,
        name: 'Lester McNally',
        gender: 'Male',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0009',
        image: client9,
        name: 'Christopher Burrell',
        gender: 'Female',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
    {
        id: '#d0010',
        image: client10,
        name: 'Mary Skeens',
        gender: 'Female',
        Age: '25Year',
        country: 'USA',
        Diagnosis: 'Individual Therapy'
    },
]
export const chatData = [
    {
        image: client1,
        name: 'Christopher',
        time: '10 Min',
        message: 'Hello',
        status: 'online',
        active: true
    },
    {
        image: dr1,
        name: 'Dr. Cristino',
        time: '20 Min',
        message: 'Hi, How are you?',
        status: 'offline',
        badge: '2',
        active: false
    },
    {
        image: client2,
        name: 'Faye',
        time: '30 Min',
        message: 'Heyy',
        status: 'offline',
        active: false
    },
    {
        image: client3,
        name: 'Ronald',
        time: '2 Hours',
        message: 'Hey, How are you sir?',
        status: 'offline',
        active: false
    },
    {
        image: client4,
        name: 'Melissa',
        time: '3 Hours',
        message: 'Good Afternoon',
        status: 'online',
        active: false
    },
    {
        image: dr2,
        name: 'Dr. Elsie',
        time: '10 Hours',
        message: 'Good Morning sir, how can i help you?',
        status: 'online',
        active: false
    },
    {
        image: client5,
        name: 'Jerry',
        time: '16 Hours',
        message: 'Please give me appointment',
        status: 'online',
        active: false
    },
    {
        image: dr3,
        name: 'Dr. Louis',
        time: '1 Days',
        message: 'Hii',
        status: 'offline',
        active: false
    },
    {
        image: client6,
        name: 'Randall',
        time: '2 Days',
        message: 'Hello Sir',
        status: 'offline',
        active: false
    },
    {
        image: client7,
        name: 'Mary',
        time: '3 Days',
        message: 'How are you sir?',
        status: 'offline',
        active: false
    },
    {
        image: client8,
        name: 'Lester',
        time: '4 Days',
        message: 'Hello',
        status: 'Hello please give me answer.',
        active: false
    },
]
export const payment = [
    {
        image: payment1,
        name: 'Mastercard',
        id: '•••• 4584',
        expires: 'Expires 12/24'
    },
    {
        image: payment2,
        name: 'Discover',
        id: '•••• 5796',
        expires: 'Expires 12/24'
    },
    {
        image: payment3,
        name: 'Rupay',
        id: '•••• 4645',
        expires: 'Expires 12/24'
    },
    {
        image: payment4,
        name: 'American',
        id: '•••• 4875',
        expires: 'Expires 12/24'
    },
]
export const appointment = [
    {
        icon: RiHeartPulseLine,
        title: 'Individual Therapy',
        name: 'Kyle Hill',
        date: '13 March',
        class: 'h3 fw-normal text-primary mb-0'
    },
    {
        icon: RiStethoscopeLine,
        title: 'Couple Therapy',
        name: 'Kyle Hill',
        date: '5 May',
        class: 'h3 fw-normal text-success mb-0'
    },
    {
        icon: RiVirusLine,
        title: 'Social Interaction',
        name: 'Kyle Hill',
        date: '19 June',
        class: 'h3 fw-normal text-warning mb-0'
    },
    {
        icon: RiDossierLine,
        title: 'Residential Treatment',
        name: 'Kyle Hill',
        date: '20 June',
        class: 'h3 fw-normal text-secondary mb-0'
    },
    {
        icon: RiEye2Line,
        title: 'Group Therapy',
        name: 'Kyle Hill',
        date: '31 Aug',
        class: 'h3 fw-normal text-info mb-0'
    },
    {
        icon: RiMicroscopeLine,
        title: 'Home Based Therapy',
        name: 'Kyle Hill',
        date: '1 Sep',
        class: 'h3 fw-normal text-danger mb-0'
    },
]

export const paymentTwo = [
    {
        name: 'Individual Therapy',
        title: 'Full bill paid',
    },
    {
        name: 'Couple Therapy',
        title: 'Full bill paid',
    },
    {
        name: 'Social Interaction',
        title: 'Full bill paid',
    },
    {
        name: 'Residential Treatment',
        title: 'Full bill paid',
    },
    {
        name: 'Group Therapy',
        title: 'Full bill paid',
    },
    {
        name: 'Home Based Therapy',
        title: 'Full bill paid',
    },
]

export const MonthlyReport = [
    {
        title: 'Treatment report',
        progress: '84%'
    },
    {
        title: 'State of being report',
        progress: '79%'
    },
    {
        title: 'Health department report',
        progress: '95%'
    },
    {
        title: 'Questionnaire',
        progress: '90%'
    },
    {
        title: 'Covid-19 report',
        progress: '75%'
    },
    {
        title: 'Blood pressure report',
        progress: '97%'
    },
]
export const patientProfile = [
    {
        icon: FiUser,
        title: 'Gender',
        name: 'Male'
    },
    {
        icon: FiMail,
        title: 'Birthday',
        name: '13th Sep 1993'
    },
    {
        icon: VscBook,
        title: 'Phone No.',
        name: '+(125) 458-8547'
    },
    {
        icon: MdFormatItalic,
        title: 'Address',
        name: 'Sydney, Australia'
    },
    {
        icon: GiMedicalDrip,
        title: 'Blood Group',
        name: 'B +'
    },
]
export const pharmaCategories = [
    {
        image: category1,
        title1: 'Skin',
        title2: 'Care'
    },
    {
        image: category2,
        title1: 'Sexual',
        title2: 'Wallness'
    },
    {
        image: category3,
        title1: 'Weight',
        title2: 'Management'
    },
    {
        image: category4,
        title1: 'Pain',
        title2: 'Relief'
    },
    {
        image: category5,
        title1: 'Heart',
        title2: 'Health'
    },
    {
        image: category6,
        title1: 'Cough',
        title2: '& Cold'
    },
    {
        image: category7,
        title1: 'Diabetes',
        title2: 'Care'
    },
    {
        image: category8,
        title1: 'Cancer',
        title2: 'Care'
    },
]
export const franchiseProcess = [
    {
        icon: FiAirplay,
        title: 'Registration'
    },
    {
        icon: FiMapPin,
        title: 'Store Location'
    },
    {
        icon: FiBriefcase,
        title: 'Agreement'
    },
    {
        icon: LuFileCheck,
        title: 'Documentation'
    },
    {
        icon: MdOutlineStorefront,
        title: 'Store Execution'
    },
    {
        icon: LuAward,
        title: 'Inauguration'
    },
]
export const productData = [
    {
        id: 1,
        image: product1,
        name: 'Thermometer',
        price: '$16.00'
    },
    {
        id: 2,
        image: product2,
        name: 'Stethoscope',
        price: '$16.00'
    },
    {
        id: 3,
        image: product3,
        name: 'Pulse oximeter',
        price: '$16.00'
    },
    {
        id: 4,
        image: product4,
        name: 'Medicine pills',
        price: '$16.00'
    },
    {
        id: 5,
        image: product5,
        name: 'Smokill smoking habit',
        price: '$16.00'
    },
    {
        id: 6,
        image: product6,
        name: 'Sanitizer',
        price: '$16.00'
    },
    {
        id: 7,
        image: product7,
        name: 'Nicotex',
        price: '$16.00'
    },
    {
        id: 8,
        image: product8,
        name: 'Medigrip',
        price: '$16.00'
    },
    {
        id: 9,
        image: product9,
        name: 'Face masks',
        price: '$16.00'
    },
    {
        id: 10,
        image: product10,
        name: 'Dettol handwash',
        price: '$16.00'
    },
    {
        id: 11,
        image: product11,
        name: 'Herbal care product',
        price: '$16.00'
    },
    {
        id: 12,
        image: product12,
        name: 'Medical equptment',
        price: '$16.00'
    },
    {
        id: 13,
        image: product13,
        name: 'Praganews',
        price: '$16.00'
    },
    {
        id: 14,
        image: product14,
        name: 'Ashwagandha churna',
        price: '$16.00'
    },
    {
        id: 15,
        image: product15,
        name: 'Beby products',
        price: '$16.00'
    },
    {
        id: 16,
        image: product16,
        name: 'Kidney tray',
        price: '$16.00'
    },
]
export const cartData = [
    {
        image: product14,
        name: 'Ashwagandha Churna',
        price: '$ 255.00',
        priceTwo: '$510.00'
    },
    {
        image: product17,
        name: 'Diabend',
        price: '$ 520.00',
        priceTwo: '$520.00'
    },
    {
        image: product18,
        name: 'Facewash',
        price: '$ 160.00',
        priceTwo: '$160.00'
    },
    {
        image: product10,
        name: 'Dettol handwash',
        price: '$ 260.00',
        priceTwo: '$260.00'
    },
]
export const faqAbout = [
    {
        icon: VscBook,
        title: 'Get started',
        desc: 'There is now an abundance of readable dummy texts required purely to fill a space.'
    },
    {
        icon: LiaFileInvoiceDollarSolid,
        title: 'Pricing plan',
        desc: 'There is now an abundance of readable dummy texts required purely to fill a space.'
    },
    {
        icon: FiThumbsUp,
        title: 'Sales questions',
        desc: 'There is now an abundance of readable dummy texts required purely to fill a space.'
    },
]
export const acccordianData = [
    {
        id: 1,
        title: 'How does it work ?',
        desc: 'Our therapy services are provided by licensed professionals who offer personalized support through in-person or virtual sessions, tailored to meet the specific needs of each individual.'
    },
    {
        id: 2,
        title: 'Do I need a designer to use Thrive Abundance ?',
        desc: 'You do not need a designer to use Thrive Abundance. The platform is user-friendly and offers customizable templates, allowing you to easily create and manage your website without design expertise.'
    },
    {
        id: 3,
        title: 'What do I need to do to start selling ?',
        desc: 'To start selling, simply sign up, set up your product listings, configure payment options, and launch your store. It\'s quick and easy to get started with Thrive Abundance!'
    },
    {
        id: 4,
        title: 'What happens when I receive an order ?',
        desc: 'When you receive an order, you\'ll be notified, and the order details will be available in your dashboard. You can then process the order, pack the items, and ship them to the customer.'
    },
]
export const commentData = [
    {
        image: client1,
        name: 'Lorenzo Peterson',
        date: '13th March 2023 at 01:25pm',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image: client2,
        name: 'Tammy Camacho',
        date: '5th May 2023 at 10:00am',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image: client3,
        name: 'Tammy Camacho',
        date: '19th June 2023 at 09:00am',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image: client4,
        name: 'Lorenzo Peterson',
        date: '20th June 2023 at 02:00pm',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
]

export const recentPost = [
    {
        image: blog7,
        title: 'Consultant Business',
        date: '13th Sep 2023'
    },
    {
        image: blog8,
        title: 'Look On The Glorious Balance',
        date: '29th Nov 2023'
    },
    {
        image: blog1,
        title: 'Research Financial.',
        date: '29th Dec 2023'
    },
]
export const contact = [
    {
        icon: FiPhone,
        title: 'Phone',
        desc: 'Please feel free to call us for assistance if any member of your family requires therapy services.',
        link: 'tel:+248-665-8601',
        name: '+ 248-665-8601'
    },
    {
        icon: FiMail,
        title: 'Email',
        desc: 'Please feel free to mail us for assistance if any member of your family requires therapy services.',
        link: 'mailto:contact@thriveabundance.com',
        name: 'thriveabundance@gmail.com'
    },
    {
        icon: FiMapPin,
        title: 'Location',
        desc: "Gerald D. Hines Waterwall Park 2800 Post Oak Blvd, Houston, TX 77056, US.",
        link: 'https://www.google.com/maps/place/Gerald+D.+Hines+Waterwall+Park/@29.735948,-95.461302,13z/data=!4m6!3m5!1s0x8640c16de81f3ca5:0xf43e0b60ae539ac9!8m2!3d29.7359479!4d-95.4613024!16zL20vMDc2dl8y?hl=en&entry=ttu&g_ep=EgoyMDI1MDMxMC4wIKXMDSoASAFQAw%3D%3D',
        name: 'View on Google map'
    },
]