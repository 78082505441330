import React, { useState } from "react";
// import IndividualTherapy from "../../assets/images/home/individual-therapy.webp"; 
import IndividualTherapy from "../../assets/images/about/individualT.jpg"; 
// import GroupTherapy from "../../assets/images/home/Group-Therapy.webp"; 
import GroupTherapy from "../../assets/images/about/socialInterac.jpg"; 
// import HomeBased from "../../assets/images/home/Home-Based.webp"; 
import familyBased from "../../assets/images/about/familyT.jpg"; 
import coupleTBased from "../../assets/images/about/coupleT01.jpg"; 
import psychiatrictMBased from "../../assets/images/about/psychiatrictM.jpg"; 
import socialInteractionBased from "../../assets/images/about/socialInteraction.jpg"; 
import ResidentialTreatment from "../../assets/images/about/ResidentialTreatment.jpg"; 
import CommunityCorrectionsModel from "../../assets/images/home/Community-Corrections-Model.webp"; 
import DualDiagnosisProgram from "../../assets/images/home/Dual-Diagnosis-Program.webp"; 
import AdultDayServices from "../../assets/images/home/Adult-Day-Services.webp"; 
// import HiMiniUserGroup  from "../../assets/icons/vander";
import {RiEyeFill,FaHome , MdBedroomChild ,FaUserFriends ,HiMiniUserGroup , AiOutlineInteraction ,RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill,RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine,TbAmbulance,RiShieldCrossLine, RiBriefcase4Line,FiAirplay,MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine,RiHourglassLine,RiHeartLine,RiHeartPulseLine,RiStethoscopeLine,RiVirusLine,RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward,LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, } from '../../assets/icons/vander'

import IcIndividualTherapy from "../../assets/images/home/I-Individual-Therapy.png"; 
import IcGroupTherapy from "../../assets/images/home/I-Group-Therapy.png"; 
import IcHomeBased from "../../assets/images/home/I-Home-Based.png"; 
import IcCommunityCorrectionsModel from "../../assets/images/home/I-CommunityCorrectionsModel.png"; 
import IcDualDiagnosisProgram from "../../assets/images/home/I-DualDiagnosisProgram.png"; 
import IcAdultDayServices from "../../assets/images/home/I-AdultDayServices.png"; 
import IcAdultDayServices1 from "../../assets/images/home/I-Psychiatric-Medication.png"; 
// Landing\src\assets\images\home\Psych_Medi1.jpg
import { Link } from "react-router-dom";

const VerticalTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Individual-Therapy",
      icon: IcIndividualTherapy, // Example icon
      content: "Our Individual Therapy program offers personalized one-on-one counseling sessions with a Licensed Master Social Worker (LMSW). This program is designed to provide private, confidential support tailored to each client’s unique mental health needs.",
      image: IndividualTherapy, // Full-size content image
      pageLink: "/individual-therapy"
    },
    {
      title: "Couple-Therapy",
      icon: IcGroupTherapy,
      content: "Our Group Therapy program provides supportive group counseling sessions led by an experienced LMSW. This program fosters a community environment where clients can share experiences and support each other.",
      image: coupleTBased,
      pageLink: "/couple-therapy"
    },
    {
      title: "Family-Therapy",
      icon: IcHomeBased,
      content: "Our Home-Based Therapy program offers therapeutic services in the comfort of your home. This program combines the expertise of LMSWs and occupational therapists to provide comprehensive in-home support.",
      image: familyBased,
      pageLink: "/family-therapy"
    },
    {
      title: "Group-Therapy",
      icon: IcCommunityCorrectionsModel, // Example icon
      content: "Our Community Corrections program supports individuals in correctional facilities and those reintegrating into the community. This program is designed to facilitate rehabilitation and social reintegration.",
      image: GroupTherapy, // Full-size content image
      pageLink: "/group-therapy"
    },
    {
      title: "Social-Interaction",
      icon: IcDualDiagnosisProgram,
      content: "Our Dual Diagnosis program addresses the needs of individuals with co-occurring mental health and substance abuse disorders. This integrated approach ensures comprehensive care for both conditions.",
      image: socialInteractionBased,
      pageLink: "/social-interaction"
    },
    {
      title: "Residential Treatment", 
      icon: IcAdultDayServices,
      content: "At Thrive Abundance, we are committed to enriching the lives of our aging population through compassionate, evidence-based adult day services. Our mission is to foster independence and promote well-being. We understand that each individual has unique needs, and our programs are designed to meet those needs with care and precision. ",
      image: ResidentialTreatment,
      pageLink: "/room-and-board"
    },
    {
      title: "Psychiatric-Medication", 
      icon: IcAdultDayServices1,
      content: "At Thrive Abundance, we are committed to enriching the lives of our aging population through compassionate, evidence-based adult day services. Our mission is to foster independence and promote well-being. We understand that each individual has unique needs, and our programs are designed to meet those needs with care and precision. ",
      image: psychiatrictMBased,
      pageLink: "/psychiatric-medication"
    },
  ];

  return (
    <div style={styles.container}>
      {/* Sidebar (Tabs) */}
      <div style={styles.sidebar}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            style={{
              ...styles.tabButton,
              backgroundColor: activeTab === index ? "#f6c52e" : "#f5f3ef",
              color: activeTab === index ? "#000" : "#152d6f",
            }}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.icon} alt="" style={styles.icon} />
            {tab.title}
          </button>
        ))}
      </div>

      {/* Content Area */}
      <div style={styles.content}>
        <img src={tabs[activeTab].image} alt="Content" style={styles.contentImage} />
        <h2 style={styles.conTitle}>{tabs[activeTab].title}</h2>
        <p>{tabs[activeTab].content}</p>
        <div className="findMore">
        <Link to={tabs[activeTab].pageLink} className="primaryBtn">
      Find Out More
    </Link>
            </div>
      </div>
    </div>
  );
};

// Inline Styles
const styles = {
  container: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    ...(window.innerWidth <= 992 && { display: "block", }),
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    width: "450px",
    maxWidth: "30%",
    backgroundColor: "#FFF",
    ...(window.innerWidth <= 992 && { width: "100%", maxWidth: "100%", }),
  },
  tabButton: {
    display: "flex",
    alignItems: "center",
    padding: "20px 20px",
    marginBottom: "12px",
    border: "none",
    cursor: "pointer",
    textAlign: "left",
    fontSize: "18px",
    transition: "0.3s",
    width: "100%",
    borderRadius: "20px",
    fontWeight: "600",
    ...(window.innerWidth <= 992 && { fontSize: "15px", padding: "15px 15px", }),
  },
  icon: {
    width: "36px",
    height: "36px",
    marginRight: "15px",
  },
  conTitle: {
    marginBottom: "15px",
    fontWeight: "700",
    fontSize: "34px",
    marginTop: "15px",
    ...(window.innerWidth <= 992 && { fontSize: "24px", }),
  },
  content: {
    flex: 1,
    paddingLeft: "60px",
    marginBottom: "40px",
    display: "block",
    fontSize: "17px",
    lineHeight: "1.7",
    letterSpacing: "0.5px",
    ...(window.innerWidth <= 992 && { paddingLeft: "0px", paddingTop: "30px" }),
    ...(window.innerWidth <= 767 && { paddingLeft: "0px", paddingTop: "30px" }),
  },
  contentImage: {
    width: "100%",
    borderRadius: "8px",
    marginBottom: "15px",
  },
};

export default VerticalTabs;
