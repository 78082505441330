import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import TransformationalSection from "../components/TransformationalImpact";
import ScheduleNowSection from "../components/ScheduleNow";
import Image01 from "../../src/assets/images/services/individual-therapy/IndividualTherapy.jpg";
import Icon01 from "../../src/assets/images/services/individual-therapy/IndividualTherapy-icon.png";
import Icon02 from "../../src/assets/images/services/individual-therapy/One-on-OneTherapy.png";
import Icon03 from "../../src/assets/images/services/individual-therapy/ConfidentialandPrivate.png";
import Icon04 from "../../src/assets/images/services/individual-therapy/Evidence-Based.png";
import Icon05 from "../../src/assets/images/services/individual-therapy/FlexibleScheduling.png";
import Icon06 from "../../src/assets/images/services/individual-therapy/Goal-Oriented.png";
import { partners } from "../data/data";

export default function GroupTherapy() {
  return (
    <>
      <Navbar
        navDark={false}
        manuClass="navigation-menu nav-left  "
        containerClass="container"
      />
      {/*************************************/}
      <section className="innerHero">
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12">
              <div className="heading-title">
                <h2 className="bannerTitle">Group Therap </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*************************************/}
      <section>
        <section className="homeAboutRow">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-1 LeftTxtCol">
                <div className="section-title">
                  <h5 className="aboutsubtitle">Overview</h5>
                  <h2 className="sectionHeading">Key Features :</h2>
                  <p className="aboutPara-desc">
                  Our Group Therapy program provides a supportive environment where individuals can share experiences, gain insights, and build connections. Led by experienced therapists, group sessions focus on personal growth, emotional support, and skill development to help participants navigate life’s challenges together.
                  </p>
                </div>
                <div className="aboutBtn">
                  <Link to="/book-a-consultation" className="primaryBtn">
                    Book a Consultation{" "}
                  </Link>
                </div>
              </div>
              <div
                Class="col-lg-6 col-md-6 mt-0"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <img className="leftColImg" src={Image01} />
              </div>
            </div>
          </div>
          <div className="container" style={{ marginTop: "65px" }}>
            <div className="row gy-4">
              {/******** 1 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon01} />
                  <h4>Supportive Group Environment</h4>
                  <p>Engage in guided discussions with peers who share similar experiences, fostering mutual support and understanding.</p>
                </div>
              </div>
              {/******** 2 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon02} />
                  <h4>Led by Licensed Therapists</h4>
                  <p>Sessions are facilitated by skilled therapists who provide expert guidance and therapeutic interventions.</p>
                </div>
              </div>
              {/******** 3 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon03} />
                  <h4>Confidential and Safe Space</h4>
                  <p>All group discussions are conducted in a secure and confidential setting to encourage open and honest conversations.</p>
                </div>
              </div>
              {/******** 4 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon04} />
                  <h4>Evidence-Based Techniques</h4>
                  <p>Benefit from scientifically proven therapy methods, such as Cognitive Behavioral Therapy (CBT) and Mindfulness-Based Stress Reduction (MBSR).</p>
                </div>
              </div>
              {/******** 5 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon05} />
                  <h4>Enhanced Social Skills</h4>
                  <p>Improve communication, emotional regulation, and interpersonal skills through group interaction and feedback.</p>
                </div>
              </div>
              {/******** 6 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon06} />
                  <h4>Flexible Scheduling</h4>
                  <p>We offer convenient session times to accommodate different schedules and commitments.</p>
                </div>
              </div>
              {/******** End *******/}
            </div>
          </div>
        </section>
      </section>
      {/* <CtaTwo/> */}
      {/*************************************/}
      <TransformationalSection />
      {/*************************************/}
      <ScheduleNowSection />
      {/*************************************/}
      <section className="py-4 bg-light">
        <div className="container ">
          <div className="row justify-content-center">
            {partners.map((item, index) => {
              return (
                <div
                  className="col-lg-2 col-md-2 col-6 text-center py-4"
                  key={index}
                >
                  <img src={item} className="avatar avatar-client" alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
