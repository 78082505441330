import React from 'react'; 
import { Link } from "react-router-dom";

import logoLight from "../assets/images/logo-light.jpg"

import {FiFacebook, FiLinkedin, FiInstagram, FiTwitter,FiMail, FiPhone, FiMapPin} from '../assets/icons/vander'

export default function Footer(){
    return(
        <>
        <footer className="">
            <div className="container">
                <div className="row ">
                    <div className="col-xl-5 col-lg-4 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link to="#" className="logo-footer">
                            {/* <img src={logoLight} height="22" className="l-light mt-2 rounded-top-1"  width="30" alt=""/> */}
                             <img src={logoLight} className="l-light mt-2 rounded-top sticky" height="auto" width="180" alt=""/>
                        </Link>
                        <p className="paraDesc mt-4 me-xl-5">Great Therapist if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                    </div>
                    <div className="col-xl-7 col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Company</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="/doctor-profile" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Therapist</Link></li>
                                    <li><Link to="/aboutus" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> About US</Link></li>
                                    <li><Link to="/contact" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Contact US</Link></li>
                                    <li><Link to="/faqs" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> FAQ</Link></li>
                                </ul>
                            </div>
                    
                            <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Services</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="/individual-therapy" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Individual Therapy</Link></li>
                                    <li><Link to="/couple-therapy" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Couple Therapy</Link></li>
                                    <li><Link to="/social-interaction" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i>Social Interaction </Link></li>
                                    <li><Link to="/room-and-board" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i>Residential Treatment</Link></li>
                                    <li><Link to="/group-therapy" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Group Theory</Link></li>
                                    <li><Link to="/family-therapy" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Family Therapy</Link></li>
                                    <li><Link to="/psychiatric-medication" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Psychiatric Medication</Link></li>
                                </ul>
                            </div>
                    
                            <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Contact US</h5>
                                <ul className="list-unstyled footer-list mt-4" style={{
                                    minWidth:'300px'
                                }}>
                                    <li className="d-flex align-items-center">
                                        <FiMail className="fea icon-sm text-foot align-middle"/>
                                        <Link to="mailto:thriveabundance@gmail.com" className="text-foot ms-2">thriveabundance@gmail.com</Link>
                                    </li>

                                    <li className="d-flex align-items-center">
                                        <FiPhone className="fea icon-sm text-foot align-middle"/>
                                        <Link to="tel:+152534-468-854" className="text-foot ms-2">+ 248-665-8601</Link>
                                    </li>

                                    <li className="d-flex align-items-center">
                                        <FiMapPin className="fea icon-sm text-foot align-middle"/>
                                        <a href="https://www.google.com/maps/place/Gerald+D.+Hines+Waterwall+Park/@29.735948,-95.461302,13z/data=!4m6!3m5!1s0x8640c16de81f3ca5:0xf43e0b60ae539ac9!8m2!3d29.7359479!4d-95.4613024!16zL20vMDc2dl8y?hl=en&entry=ttu&g_ep=EgoyMDI1MDMxMC4wIKXMDSoASAFQAw%3D%3D" className="video-play-icon text-foot ms-2" target='_blank'>View on Google map</a>
                                    </li>
                                </ul>

                                <ul className="list-unstyled social-icon footer-social mb-0 mt-4">
                                    <li className="list-inline-item me-2"><Link to="#" className="rounded-pill"><FiFacebook className="fea icon-sm fea-social"/></Link></li>
                                    <li className="list-inline-item me-2"><Link to="#" className="rounded-pill"><FiInstagram className="fea icon-sm fea-social"/></Link></li>
                                    <li className="list-inline-item me-2"><Link to="#" className="rounded-pill"><FiTwitter className="fea icon-sm fea-social"/></Link></li>
                                    <li className="list-inline-item me-2"><Link to="#" className="rounded-pill"><FiLinkedin className="fea icon-sm fea-social"/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="pt-4 footer-bar">
                    <div className="row align-items-center">
                         <div className="col-sm-6">
                            <div className="text-sm-start text-center">
                                <p className="mb-0">{new Date().getFullYear()}© Thrive Abundance.</p>
                            </div>
                        </div> 
                        <div className="col-sm-6" >
                            <div className="text-sm-end text-center">
                                <p className="footerBtn mb-0" /*style={{marginRight:"32px"}}*/><Link to="/book-a-consultation" className="text-bold me-1">Book A Consultaion</Link></p>
                            </div>
                        </div> 
                        {/* <div className="col-sm-6 mt-4 mt-sm-0">
                            <ul className="list-unstyled footer-list text-sm-end text-center mb-0"  style={{marginRight:"28px"}}>
                                <li className="list-inline-item"><Link to="/book-a-consultation" className="text-bold me-1" >Book A Consultaion</Link></li> 
                                 <li className="list-inline-item"><Link to="/privacy" className="text-foot me-2">Privacy</Link></li>
                                <li className="list-inline-item"><Link to="/aboutus" className="text-foot me-2">About</Link></li>
                                <li className="list-inline-item"><Link to="/contact" className="text-foot me-2">Contact</Link></li> 
                             </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}