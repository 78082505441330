import React from "react";
import { Link } from "react-router-dom";

// import bg1 from '../assets/images/bg/department.jpg'
import bg1 from "../assets/images/bg/faqs.jpg";

import Navbar from "../components/navbar";

import {
  RiSearchLine,
  FiArrowRight,
  FiPhone,
  FiMail,
} from "../assets/icons/vander";
import { faqAbout } from "../data/data";
import Accordion from "../components/accordion";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import TransBGImage from "../assets/images/services/ScheduleNoWBG.jpg";

export default function Faqs() {
  return (
    <>
      <Navbar
        navDark={false}
        manuClass="navigation-menu nav-left  "
        containerClass="container"
      />
      {/*************************************/}
      <section className="innerHero">
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12">
              <div className="heading-title">
                <h2 className="bannerTitle">Frequently Asked Questions </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" style={{ marginTop: "-30px" }}>
        <div className="container mt-0 ">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4
                  className="mb-4"
                  style={{ fontSize: "32px", fontWeight: "700" }}
                >
                  General Questions
                </h4>
                <p
                  className="para-desc mx-auto text-muted mb-0"
                  style={{ fontSize: "17px", color: "#444" }}
                >
                  Great Therapist if you need your family member to get
                  effective immediate assistance, emergency treatment or a
                  simple consultation.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-lg-12 mt-1 pt-2"
              style={{ marginBottom: "-40px" }}
            >
              <Accordion />
            </div>
          </div>
        </div>

        <div className="ScheduleWrapp">
          <div
            className="container"
            style={{
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "0px 80px 40px -70px rgba(0, 0, 0, 0.2)",
              background: "rgba(245, 243, 239, 0.7)",
            }}
          >
            <div className="row">
              <div className="col-6 SchLeft">
                <h2>Still have a questions ?</h2>
                <p>
                  Great Therapist if you need your family member to get
                  effective immediate assistance, emergency treatment or a
                  simple consultation.
                </p>
                <Link to="/contact" className="primaryBtn">
                  Contact Us
                </Link>
              </div>
              <div
                className="col-6 SchRight"
                style={{
                  backgroundImage: `url(${TransBGImage})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
