import React from "react";
import { Link } from "react-router-dom";
import 'bootstrap';
import Navbar from "../../components/navbar";
import bg1 from '../../assets/images/bg/01.jpg'
// import bg2 from '../../assets/images/bg/img1.jpg'
import logoVector from '../../assets/images/bg/vector-logo.png'
import bg2 from '../../assets/images/bg/home-1-background-1.jpg'
import bgAbout from '../../assets/images/bg/about-bg-overlay.png'
// import heroImage from '../../assets/images/bg/home-1-1.webp'
// import heroImage from '../../assets/images/bg/bg1.jpg'
import heroImage from '../../assets/images/bg/homebg.png'
import shareBg from '../../assets/images/home/sharedWrapp-bg.png'
import shareimage from '../../assets/images/about/socialInteraction.jpg'
import SharedExperience from '../../assets/images/about/SharedExperience.jpg'
import CashOnly from '../../assets/images/about/Cash-Only.jpg'

import AboutSectionImage from '../../assets/images/about/socialInteraction.jpg'
// import shareimage from '../../assets/images/home/shared-image.jpg'
// import shareimageTwo from '../../assets/images/home/shared-image02.jpg'
import shareimageTwo from '../../assets/images/about/coupleT01.jpg'
import circleLogo from '../../assets/images/home/vector-logo.png'
import logoIcon from '../../assets/images/logo-icon.png'
import FeatureOne from "../../components/features/featuresOne";
import AboutImage from "../../components/aboutImage";
import DrTimeTable from "../../components/drTimeTable";
import CtaOne from "../../components/cta/ctaOne";
import Patients from "../../components/patients";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import ServiceTab from "../../pages/index/VerticalTabs";

import { RiArrowRightLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter, FiCalendar, FiClock } from '../../assets/icons/vander'

import { medicalServices, doctorData, blogData, partners } from "../../data/data";

export default function Index() {
    return (
        <>
            <Navbar manuClass="navigation-menu nav-left nav-light" containerClass="container"/>

            <section className="heroWrapper " style={{ backgroundImage: `url(${bg2})`, backgroundSize: "cover", }}>
                {/**<div className="bg-overlay bg-overlay-custom"></div>**/}
                <div className="container">
                    <div className="row t-lg-0 heroRow">
                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 leftHeroCol">

                            <div className="heading-title" >
                                {/* <img src={logoIcon} height="50" alt=""/> */}
                                <h2 className="heroTitle mt-0 "> Empowering You to Thrive: Compassionate Therapy for a Balanced Life</h2>
                                <p className="heroSubtitle">Whether you're facing stress, anxiety, or life transitions, we offer a safe space for healing and personal growth. </p>
                                <div className="mt-4 pt-2">
                                    <div className="btnSpace" >
                                        <Link to="/book-a-consultation" className="primaryBtn"> Book a Consultation </Link>
                                        <div className="phoneBox">
                                            <h5>Give Us a Call:</h5>
                                            <a href="tel:734-716-8913"> + 248-665-8601 </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "relative" }} className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                            <img className="heroImageImg" alt="" src={heroImage} />
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ backgroundImage: `url(${bgAbout})`, backgroundSize: "cover", backgroundPosition: "top center" }}>
                <section className="homeQuote">
                    <div className="container mt-0 mt-60">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" style={{ margin: "0 auto" }}>
                            <img className="logoVectorImg" alt="" src={logoVector} />
                            <h4>We understand that life's challenges can feel overwhelming. At Thrive Abundance, we provide professional therapy services tailored to your unique needs. Our goal is to help you build resilience, enhance emotional well-being, and cultivate a fulfilling life.</h4>
                            <h5>A Loving Space for Healing and Growth</h5>
                        </div>
                    </div>
                </section>
                <section className="homeAboutRow">
                    {/** <FeatureOne/> **/}
                    <div className="container">
                        <div className="row align-items-center">
                            <AboutImage colClass="col-lg-6 col-md-6" />
                            <div className="col-lg-6 col-md-6 mt-1 aboutRightCol">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h5 className="aboutsubtitle">Compassionate Care, Professional Support</h5>
                                        <h2 className="aboutTitle">About Our Treatments</h2>
                                        <p className="aboutPara-desc">At Thrive Abundance, we believe that mental and emotional well-being is the foundation of a meaningful life. Our licensed therapists are dedicated to providing evidence-based, personalized therapy in a warm and supportive environment.</p>
                                        {/* <p className="aboutPara-desc">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words.</p> */}
                                        <h5 className="approchTitle"> Our Approach -</h5>
                                        <ul className="listPoints">
                                            <li>Client-centered and holistic therapy </li>
                                            <li>A safe, non-judgmental space for self-discovery</li>
                                            <li>Professional guidance rooted in research-backed methods</li>
                                        </ul>
                                       </div>
                                    <div className="aboutBtn">
                                        <Link to="/aboutus" className="primaryBtn">More about us </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <section className="homeServiceWrapp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 ">
                            <div style={{ margin: "0 auto 50px auto" }} className="col-lg-8 col-md-8 col-sm-12 col-xs-12 MaxWidthFull">
                                <h5 style={{ textAlign: "center" }} className="subtitle">Services We Offer</h5>
                                <h2 style={{ textAlign: "center" }} className="sectionTitle">Transformative Therapy and Counseling Services</h2>
                                <p style={{ textAlign: "center" }} className="paraDesc">At Thrive Abundance, we understand that each individual’s journey to mental and emotional well-being is unique. Our mission is to provide personalized, empathetic, and accessible therapy services.</p>
                            </div>
                            <ServiceTab />
                        </div>
                    </div>
                </div>
            </section>
            <section className="sharedWrapp" style={{ backgroundImage: `url(${shareBg})`, backgroundSize: "cover", }}>
                {/** <FeatureOne/> **/}
                <div className="container">
                    <div className="row align-items-center reverseMobile">
                        <div className="col-lg-6 col-md-6 sharedRowLeft">
                            <div className="section-title">
                                <h2 className="aboutTitle">Shared Experience and Support</h2>
                                <p className="aboutPara-desc">Group therapy at Thrive Abundance offers a supportive environment where you can connect with others facing similar challenges. Our sessions, facilitated by experienced therapists, foster a sense of community and collective healing, addressing issues like trauma, grief, shame, and doubt.</p>
                            </div>
                            <div className="aboutBtn">
                                <Link to="/aboutus" className="primaryBtn">I Want to Learn Thrive Abundance</Link>
                            </div>
                        </div>
                        <div Class="col-lg-6 col-md-6 mt-0" style={{ display: "flex", justifyContent: "right" }}>
                            <img className="shareRightImg" src={SharedExperience} />
                        </div>
                    </div>
                </div>
                <div className="container onlinePortWrapp">
                    <div className="rotateLogo">
                        <img alt="Rotating" className="rotating-image" width="200" src={circleLogo} />
                    </div>
                    <div className="row align-items-center" >
                        <div Class="col-lg-6 col-md-6 col-sm-6"  style={{ display: "flex", justifyContent: "right" }}>
                            <img className="shareLeftImg" src={CashOnly} />
                        </div>

                        <div className="col-lg-6 col-md-6 mt-1 aboutRightCol">
                            <div className="ms-lg-4">
                                <div className="section-title">
                                    <h5 className="aboutsubtitle">How To Make Cash Payments</h5>
                                    <h2 className="aboutTitle">Cash-Only Payments Until 2025!</h2>
                                    <p className="aboutPara-desc">Effective Immediately: Thrive Abundance will be accepting cash-only payments through our online portal for all services until December 31, 2025.</p>
                                    <p className="aboutPara-desc">We appreciate your understanding and cooperation as we make this transition</p>
                                </div>

                                <div className="aboutBtn">
                                    <Link to="/aboutus" className="primaryBtn">More about us </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/**  
        <section className="section">
            <div className="container mt-100 mt-60">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title mb-4 pb-2 text-center">
                            <span className="badge rounded-pill bg-soft-primary mb-3">Services</span>
                            <h4 className="title mb-4">Our Medical Services</h4>
                            <p className="text-muted mx-auto para-desc mb-0">Great Therapists if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    {medicalServices.slice(0,8).map((item,index) => {
                        let Icon = item.icon
                        return(
                        <div className="col-xl-3 col-md-4 col-12 mt-5" key={index}>
                            <div className="card features feature-primary border-0">
                                <div className="icon text-center rounded-md">
                                    <Icon className="h3 mb-0"/>
                                </div>
                                <div className="card-body p-0 mt-3">
                                    <Link to="/departments" className="title text-dark h5">{item.title}</Link>
                                    <p className="text-muted mt-3">{item.desc}</p>
                                    <Link to="/departments" className="link">Read More <RiArrowRightLine className="align-middle"/></Link>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </section>
        

        <DrTimeTable/>

        **/}

            {/**   
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-4">Therapists</h4>
                            <p className="text-muted mx-auto para-desc mb-0">Great Therapists if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center justify-content-center">
                    {doctorData.slice(0,1).map((item, index) => {
                        return(
                            <div className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                                <div className="card team border-0 rounded shadow overflow-hidden">
                                    <div className="team-img position-relative">
                                        <img src={item.image} className="img-fluid" alt=""/>
                                        <ul className="list-unstyled team-social mb-0">
                                            <li><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons"/></Link></li>
                                            <li className="mt-2 ms-0"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons"/></Link></li>
                                            <li className="mt-2 ms-0"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiGithub className="icons"/></Link></li>
                                            <li className="mt-2 ms-0"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons"/></Link></li>
                                        </ul>
                                    </div>
                                    <div className="card-body content text-center">
                                        <Link to="/doctor-team-one" className="title text-dark h5 d-block mb-0">{item.name}</Link>
                                        <small className="text-muted speciality">{item.speciality}</small>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                     <div className="col-12 mt-4 pt-2 text-center">
                        <Link to="/doctor-team-one" className="btn btn-primary">See More</Link>
                    </div> 
                </div>
            </div>
        </section>
        **/}
            <section className="counterWrapp" style={{ backgroundColor: "#f6c52e",}}>
                <CtaOne/>
            </section>

            <section className="section testimonialReview">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8 MaxWidthFull" style={{ margin: "auto" }}>
                            <div className="section-title text-center pb-2">
                                <h4 className="sectionTitle">What Our Community Says</h4>

                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-8 pt-2 text-center">
                            <Patients />
                        </div>
                    </div>
                </div>

            </section>

            <section className="blogNewsWrapp">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8 MaxWidthFull" style={{ margin: "auto" }}>
                            <div className="section-title text-center">
                                <h4 className="sectionTitle">Latest News & Blogs</h4>
                                <p className="paraDesc">Great Therapists if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {blogData.slice(0, 3).map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-12 mt-1 pt-2" key={index}>
                                    <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                                        <img src={item.image} className="img-fluid" alt="" />
                                        <div className="card-body p-4">
                                            <ul className="list-unstyled mb-2 ">
                                                <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="text-dark h6 me-1 mb-0" />{item.date}</li>
                                                <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="text-dark h6 me-1 mb-0 " />{item.time}</li>
                                            </ul>
                                            <Link to={`/blog-detail/${item.id}`} className="text-dark title h5">{item.title}</Link>
                                            <div className="post-meta d-flex justify-content-between mt-3">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="mdi mdi-heart-outline me-1"></i>{item.like}</Link></li>
                                                    <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="mdi mdi-comment-outline me-1"></i>{item.comment}</Link></li>
                                                </ul>
                                                <Link to={`/blog-detail/${item.id}`} className="link">Read More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="py-4 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        {partners.map((item, index) => {
                            return (
                                <div className="col-lg-2 col-md-2 col-6 text-center py-4" key={index}>
                                    <img src={item} className="avatar avatar-client" alt="" />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    )
}