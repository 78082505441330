import React from "react";
import TransBGImage from "../assets/images/services/Transformational-Bg.jpg";
import MonthIcon from "../assets/images/services/month-icon.png";

function TransformationalImpact() {
  return (
    <>
      <div
        className="TransformationalWrapp"
        style={{
          backgroundImage: `url(${TransBGImage})`,
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4
                  className="sectionTitle"
                  style={{ color: "#FFF", marginBottom: "30px" }}
                >
                  Our Transformational Impact
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="inTransBox">
                <img className="monthIco" src={MonthIcon} alt="Month Icon" />
                <h4>Within 1 Month:</h4>
                <p className=" para-desc mx-auto">
                  Clients often experience a reduction in anxiety, improved
                  mood, and an increased sense of hope after their initial
                  sessions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="inTransBox">
                <img className="monthIco" src={MonthIcon} alt="Month Icon" />
                <h4>Within 3 Months:</h4>
                <p className=" para-desc mx-auto">
                  Noticeable improvements in coping skills, communication in
                  relationships, and the ability to manage stress and life
                  challenges.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="inTransBox">
                <img className="monthIco" src={MonthIcon} alt="Month Icon" />
                <h4>Within 6 Months:</h4>
                <p className=" para-desc mx-auto">
                  Significant reduction in symptoms of anxiety, depression, or
                  trauma. Clients often report increased self-confidence,
                  stronger relationships, and improved quality of life.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="inTransBox">
                <img className="monthIco" src={MonthIcon} alt="Month Icon" />
                <h4>Within 12 Months:</h4>
                <p className=" para-desc mx-auto">
                  Clients who complete our long-term therapy programs often
                  experience transformative changes, including sustained mental
                  wellness, strengthened support systems, and a renewed sense of
                  purpose. Availability and Hours of Operation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransformationalImpact;
