import React, { useState } from "react";
import { Link } from "react-router-dom";

// import profileImage from '../../assets/images/doctors/dr-profile.png'
import Navbar from "../../components/navbar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import KyleHill from "../../assets/images/Profile/KyleHill.webp";
import ProfileBg from "../../assets/images/Profile/ProfileBg.jpg";
import Footer from "../../components/footer";

import IcIndividualTherapy from "../../assets/images/home/I-Individual-Therapy.png";
import IcGroupTherapy from "../../assets/images/home/I-Group-Therapy.png";
import IcHomeBased from "../../assets/images/home/I-Home-Based.png";
import IcCommunityCorrectionsModel from "../../assets/images/home/I-CommunityCorrectionsModel.png";
import IcDualDiagnosisProgram from "../../assets/images/home/I-DualDiagnosisProgram.png";
import IcAdultDayServices from "../../assets/images/home/I-AdultDayServices.png";
import IcAdultDayServices1 from "../../assets/images/home/I-Psychiatric-Medication.png";

import {
  doctorData,
  experienceData,
  patientsData,
  partners,
} from "../../data/data";

import {
  FiHeart,
  RiMapPinLine,
  RiTimeLine,
  RiMoneyDollarCircleLine,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  FiTwitter,
  FiArrowRight,
  RiTimeFill,
  FiPhone,
  FiMail,
  BsCursor,
} from "../../assets/icons/vander";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

export default function DoctorProfile() {
  let [activeIndex, setActiveIndex] = useState(1);

  let settings = {
    container: ".slider-range-four",
    items: 3,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  let settings2 = {
    container: ".client-review-slider",
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
  };
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="TherapistProfile"
        style={{
          backgroundImage: `url(${ProfileBg})`,
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <img className="KyleImg" src={KyleHill} alt="" />
            </div>
            <div className="col-12">
              <div className="KyleBio">
                <h4>Kyle Hill</h4>
                <h5>CEO & Founder | Therapist</h5>
                <p className="para-desc">
                  Kyle Hill, a licensed clinical social worker with extensive
                  experience in social work, homelessness support, probation,
                  parole, corrections, and community outreach, our practice
                  provides personalized individual and group sessions. Using
                  evidence-based modalities like cognitive-behavioral therapy,
                  mindfulness, solution-focused therapy, and dialectical
                  behavior therapy, we address anxiety, depression, stress,
                  behavioral problems, trauma, and life transitions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/********************************************/}
      <section className="KyleInfoTab">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card border-0">
                <ul className="nav nav-pills nav-justified flex-column flex-sm-row bg-light">
                  {/********* Overview Title *********/}
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 1 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(1)}
                    >
                      <div className="tabTitleTxt">
                        <h5 className="mb-0">Overview</h5>
                      </div>
                    </Link>
                  </li>
                  {/********* Experience Title *********/}
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 2 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(2)}
                    >
                      <div className="tabTitleTxt">
                        <h5 className="mb-0">Experience</h5>
                      </div>
                    </Link>
                  </li>
                  {/********* Reviews Title *********/}
                  {/* <li className="nav-item">
                                    <Link className={`${activeIndex === 3 ? 'active' : ''} nav-link rounded-0`} to="#" onClick={() => setActiveIndex(3)}>
                                        <div className="tabTitleTxt">
                                            <h5 className="mb-0">Reviews</h5>
                                        </div>
                                    </Link>
                                </li> */}
                  {/********* Location Title *********/}
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 4 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(4)}
                    >
                      <div className="tabTitleTxt">
                        <h5 className="tabTitleHead mb-0">Location</h5>
                      </div>
                    </Link>
                  </li>
                  {/********* TimeTable Title *********/}
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 5 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(5)}
                    >
                      <div className="tabTitleTxt">
                        <h5 className="mb-0 ">Time Table</h5>
                      </div>
                    </Link>
                  </li>
                </ul>

                <div className="tab-content mt-4">
                  {/********* Overview Text *********/}
                  {activeIndex === 1 ? (
                    <div className="tab-pane fade show active">
                      <p className=" mt-4">
                        As a Licensed Clinical Social Worker (LCSW), I offer
                        cash-only, affordable, personalized therapy for kids,
                        teens, young adults, middle-aged adults, and aging
                        adults. Specializing in individual and group sessions, I
                        use evidence-based modalities like cognitive-behavioral
                        therapy, mindfulness, solution-focused therapy, and
                        dialectical behavior therapy.
                      </p>

                      <h6 class="title fw-bold">Specialties: </h6>

                      <ul className="list-unstyled ServiceListIcon">
                        <li className="mt-1 ms-0">
                          <img
                            src={IcIndividualTherapy}
                            alt="Individual Therapy"
                          />
                          <span>Individual Therapy</span>
                        </li>
                        <li className="mt-1 ms-0">
                          <img src={IcGroupTherapy} alt="Couple Therapy" />
                          <span>Couple Therapy</span>
                        </li>
                        <li className="mt-1 ms-0">
                          <img src={IcHomeBased} alt="Family Therapy" />
                          <span>Family Therapy</span>
                        </li>
                        <li className="mt-1 ms-0">
                          <img
                            src={IcCommunityCorrectionsModel}
                            alt="Group Therapy"
                          />
                          <span>Group Therapy</span>
                        </li>
                        <li className="mt-1 ms-0">
                          <img
                            src={IcDualDiagnosisProgram}
                            alt="Social Interaction Therapy"
                          />
                          <span>Social Interaction Therapy</span>
                        </li>
                        <li className="mt-1 ms-0">
                          <img
                            src={IcAdultDayServices}
                            alt="Residential Therapy"
                          />
                          <span>Residential Therapy</span>
                        </li>
                        <li className="mt-1 ms-0">
                          <img
                            src={IcAdultDayServices1}
                            alt="Psychiatric Medication"
                          />
                          <span>Psychiatric Medication</span>
                        </li>
                      </ul>

                      {/* <h6>My Team: </h6>

                                        <div className="row">
                                            {doctorData.slice(0,4).map((item,index) =>{
                                                return(
                                                    <div className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                                                        <div className="card team border-0 rounded shadow overflow-hidden">
                                                            <div className="team-person position-relative overflow-hidden">
                                                                <img src={item.image} className="img-fluid" alt=""/>
                                                                <ul className="list-unstyled team-like">
                                                                    <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons"/></Link></li>
                                                                </ul>
                                                            </div>
                                                            <div className="card-body">
                                                                <Link to="#" className="title text-dark h5 d-block mb-0">{item.name}</Link>
                                                                <small className="text-muted speciality">{item.speciality}</small>
                                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                                    <ul className="list-unstyled mb-0">
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                    </ul>
                                                                    <p className="text-muted mb-0">5 Star</p>
                                                                </div>
                                                                <ul className="list-unstyled mt-2 mb-0">
                                                                    <li className="d-flex align-items-center ms-0">
                                                                        <RiMapPinLine className="text-primary align-middle mb-0"/>
                                                                        <small className="text-muted ms-2">{item.location}</small>
                                                                    </li>
                                                                    <li className="d-flex mt-2 align-items-center ms-0">
                                                                        <RiTimeLine className="text-primary align-middle mb-0"/>
                                                                        <small className="text-muted ms-2">{item.time}</small>
                                                                    </li>
                                                                    <li className="d-flex mt-2 align-items-center ms-0">
                                                                        <RiMoneyDollarCircleLine className="text-primary align-middle mb-0"/>
                                                                        <small className="text-muted ms-2">{item.charges}</small>
                                                                    </li>
                                                                </ul>
                                                                <ul className="list-unstyled mt-2 mb-0">
                                                                    <li className="list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons"/></Link></li>
                                                                    <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons"/></Link></li>
                                                                    <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiGithub className="icons"/></Link></li>
                                                                    <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons"/></Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {/********* Experience Text *********/}
                  {activeIndex === 2 ? (
                    <div className="tab-pane fade show active">
                      <h5 className="mb-1 title fw-bold">Experience:</h5>

                      <p className=" mt-4">
                        In my practice, I frequently utilize Cognitive
                        Behavioral Therapy (CBT). During our first session,
                        we'll discuss your reasons for seeking therapy and
                        identify the best strategies to help you move forward.
                        CBT focuses on understanding how your thoughts influence
                        your emotions and experiences. Together, we'll work on
                        reshaping these thoughts to enhance your emotional
                        well-being to promote positivity.
                      </p>
                      <h6>Professional Experience:</h6>
                      <div className="row">
                        <div className="col-12 mt-4">
                          <div className="col-md-12">
                            <div className="slider-range-four tiny-timeline">
                              <TinySlider settings={settings}>
                                {experienceData.map((item, index) => {
                                  return (
                                    <div
                                      className="tiny-slide text-center"
                                      key={index}
                                    >
                                      <div className="card border-0 p-4 item-box mb-2 shadow rounded">
                                        <p className=" mb-0">{item.time}</p>
                                        <h6 className="mt-1">{item.title}</h6>
                                        <p className=" mb-0">{item.name}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </TinySlider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/********* Reviews Text *********/}
                  {activeIndex === 3 ? (
                    <div className="tab-pane fade show active">
                      <div className="row justify-content-center">
                        <div className="col-lg-8 mt-4 pt-2 text-center">
                          <div className="client-review-slider">
                            <TinySlider settings={settings2}>
                              {patientsData.map((item, index) => {
                                return (
                                  <div
                                    className="tiny-slide text-center"
                                    key={index}
                                  >
                                    <p className="text-muted fw-normal fst-italic">
                                      {item.desc}
                                    </p>
                                    <img
                                      src={item.image}
                                      className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3"
                                      alt=""
                                    />
                                    <ul className="list-unstyled mb-0">
                                      <li className="list-inline-item">
                                        <i className="mdi mdi-star text-warning"></i>
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="mdi mdi-star text-warning"></i>
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="mdi mdi-star text-warning"></i>
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="mdi mdi-star text-warning"></i>
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="mdi mdi-star text-warning"></i>
                                      </li>
                                    </ul>
                                    <h6 className="text-primary">
                                      {item.name}{" "}
                                      <small className="text-muted">
                                        {item.title}
                                      </small>
                                    </h6>
                                  </div>
                                );
                              })}
                            </TinySlider>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        {partners.map((item, index) => {
                          return (
                            <div
                              className="col-lg-2 col-md-2 col-6 text-center py-4"
                              key={index}
                            >
                              <img
                                src={item}
                                className="avatar avatar-client"
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/********* Location Map *********/}
                  {activeIndex === 4 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12">
                          <div className="card map border-0">
                            <div className="card-body p-0">
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                style={{ border: "0" }}
                                title="Thrive"
                                className="rounded"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/********* TimeTable Text *********/}
                  {activeIndex === 5 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="card border-0 p-3 rounded shadow">
                            <ul className="list-unstyled mb-0">
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Monday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Tuesday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Wednesday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Thursday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Friday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Saturday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                              <li className="d-flex justify-content-between mt-2">
                                <p className=" mb-0 d-flex align-items-center">
                                  <RiTimeFill className="text-primary align-middle h5 mb-0 me-1" />{" "}
                                  Sunday
                                </p>
                                <p className="text-primary mb-0">
                                  <span className="text-dark">Time:</span> 8.00
                                  am - 8.00 pm
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                          <div className="card border-0 text-center features feature-primary">
                            <div className="icon text-center rounded-circle mx-auto bg-primary p-3">
                              <span className=" mb-0">
                                <FiPhone className=" h3" />
                              </span>
                            </div>

                            <div className="card-body p-0 mt-4">
                              <h5 className="title fw-bold ">Phone</h5>
                              <p className="">
                                Please feel free to call us for assistance if
                                any member of your family requires therapy
                                services.
                              </p>
                              <Link to="tel:248-665-8601" className="link">
                                +248-665-8601
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                          <div className="card border-0 text-center features feature-primary">
                            <div className="icon text-center rounded-circle mx-auto bg-primary p-3">
                              <span className="mb-0">
                                <FiMail className="h3" />
                              </span>
                            </div>

                            <div className="card-body p-0 mt-4">
                              <h5 className="title fw-bold ">Email</h5>
                              <p className="">
                                Please feel free to mail us for assistance if
                                any member of your family requires therapy
                                services.
                              </p>
                              <Link
                                to="mailto:thriveabundance@gmail.com"
                                className="link"
                              >
                                thriveabundance@gmail.com
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <AdminFooter /> */}
      <Footer />
      <ScrollTop />
    </>
  );
}
