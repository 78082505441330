import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import TransformationalSection from "../components/TransformationalImpact";
import ScheduleNowSection from "../components/ScheduleNow";
import Image01 from "../../src/assets/images/services/individual-therapy/IndividualTherapy.jpg";
import Icon01 from "../../src/assets/images/services/individual-therapy/IndividualTherapy-icon.png";
import Icon02 from "../../src/assets/images/services/individual-therapy/One-on-OneTherapy.png";
import Icon03 from "../../src/assets/images/services/individual-therapy/ConfidentialandPrivate.png";
import Icon04 from "../../src/assets/images/services/individual-therapy/Evidence-Based.png";
import Icon05 from "../../src/assets/images/services/individual-therapy/FlexibleScheduling.png";
import Icon06 from "../../src/assets/images/services/individual-therapy/Goal-Oriented.png";
import { partners } from "../data/data";

export default function PsychiatricMedication() {
  return (
    <>
      <Navbar
        navDark={false}
        manuClass="navigation-menu nav-left  "
        containerClass="container"
      />
      {/*************************************/}
      <section className="innerHero">
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12">
              <div className="heading-title">
                <h2 className="bannerTitle">Psychiatric Medication</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*************************************/}
      <section>
        <section className="homeAboutRow">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-1 LeftTxtCol">
                <div className="section-title">
                  <h5 className="aboutsubtitle">Overview</h5>
                  <h2 className="sectionHeading">Key Features :</h2>
                  <p className="aboutPara-desc">
                  Our Psychiatric Medication program provides expert psychiatric evaluations and medication management for individuals dealing with mental health conditions. Led by licensed psychiatrists, this program ensures personalized treatment plans tailored to each patient’s unique needs, helping to stabilize mood, manage symptoms, and improve overall well-being.
                  </p>
                </div>
                <div className="aboutBtn">
                  <Link to="/book-a-consultation" className="primaryBtn">
                    Book a Consultation{" "}
                  </Link>
                </div>
              </div>
              <div
                Class="col-lg-6 col-md-6 mt-0"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <img className="leftColImg" src={Image01} />
              </div>
            </div>
          </div>
          <div className="container" style={{ marginTop: "65px" }}>
            <div className="row gy-4">
              {/******** 1 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon01} />
                  <h4>Comprehensive Psychiatric Evaluations</h4>
                  <p>Receive in-depth assessments from experienced psychiatrists to determine the most effective treatment plan.</p>
                </div>
              </div>
              {/******** 2 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon02} />
                  <h4>Personalized Medication Management</h4>
                  <p>Medications are prescribed and adjusted based on your individual needs and response to treatment.</p>
                </div>
              </div>
              {/******** 3 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon03} />
                  <h4>Confidential and Supportive Care</h4>
                  <p>All consultations are conducted in a private and professional setting, ensuring your comfort and confidentiality.</p>
                </div>
              </div>
              {/******** 4 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon04} />
                  <h4>Evidence-Based Treatment Approaches</h4>
                  <p>Our medication plans follow the latest clinical research and psychiatric guidelines to ensure effectiveness and safety.</p>
                </div>
              </div>
              {/******** 5 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon05} />
                  <h4>Ongoing Monitoring and Adjustments</h4>
                  <p>Regular follow-ups allow for careful monitoring of medication effectiveness and side effects, with adjustments as needed.</p>
                </div>
              </div>
              {/******** 6 *******/}
              <div Class="col-lg-4 col-md-4">
                <div className="serviceIconBox">
                  <img src={Icon06} />
                  <h4>Integrated Therapy Options</h4>
                  <p>Psychiatric medication can be combined with therapy for a holistic approach to mental health treatment.</p>
                </div>
              </div>
              {/******** End *******/}
            </div>
          </div>
        </section>
      </section>
      {/* <CtaTwo/> */}
      {/*************************************/}
      <TransformationalSection />
      {/*************************************/}
      <ScheduleNowSection />
      {/*************************************/}
      <section className="py-4 bg-light">
        <div className="container ">
          <div className="row justify-content-center">
            {partners.map((item, index) => {
              return (
                <div
                  className="col-lg-2 col-md-2 col-6 text-center py-4"
                  key={index}
                >
                  <img src={item} className="avatar avatar-client" alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
