import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.jpg";
import logoDark1 from "../assets/images/logo-light.jpg";
import logoLight1 from "../assets/images/logo-light.jpg";
import dr1 from "../assets/images/doctors/01.jpg";

import Offcanvas from "react-bootstrap/Offcanvas";
// import image from '../assets/images/mobile-app.svg'
import image from "../assets/images/img5.jpg";

import {
  FiSettings,
  FiSearch,
  GrDashboard,
  LiaSignOutAltSolid,
  FiShoppingCart,
  FiDribbble,
  RiBehanceLine,
  FaFacebookF,
  FiInstagram,
  FiTwitter,
  LuMail,
  LuGlobe,
} from "../assets/icons/vander";
import { Button } from "bootstrap";

export default function Navbar({ navDark, manuClass, containerClass }) {
  let [show, setShow] = useState(false);
  let [showTwo, setShowTwo] = useState(false);
  let [scroll, setScroll] = useState(false);
  let [isMenu, setisMenu] = useState(false);
  let [modal, setModal] = useState(false);

  let handleClose = () => setShow(false);
  let handleShow = () => setShow(true);

  let handleCloseTwo = () => setShowTwo(false);
  let handleShowTwo = () => setShowTwo(true);

  let [manu, setManu] = useState("");
  let location = useLocation();

  useEffect(() => {
    let current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setManu(current);

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    window.scrollTo(0, 0);
    const closeModal = () => {
      setModal(false);
    };
    document.addEventListener("mousedown", closeModal);
    return () => {
      document.removeEventListener("mousedown", closeModal);
    };
  }, []);

  let toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {

              var submenu = elem.target.nextElementSibling.nextElementSibling;
              // console.log(submenu)
              submenu?.classList?.toggle("open");
            }
          }
        });
      });
    }
  };
  return (
    <header
      id="topnav"
      className={`${scroll ? "nav-sticky" : ""} navigation sticky bg-white `}
    >
      <div
        className={containerClass}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/***** Main Logo *****/}

        <div className="mainLogo">
          {navDark === true ? (
            <Link className="logo fixed-top" to="/">
              {/* <img src={logoLight} height="22" className="logo-light-mode" alt=""/> */}
              <img
                src={logoLight}
                className="l-dark mt-1 rounded-top ms-3 mb-2"
                width="70"
                height="60"
                alt=""
              />
              <img
                src={logoLight}
                className="l-light mt-1 rounded-top ms-3 mb-2"
                width="70"
                height="60"
                alt=""
              />
            </Link>
          ) : (
            <Link className="logo " to="/">
              <span className="logo-light-mode ">
                <img
                  src={logoLight}
                  className="l-dark  rounded-top "
                  height="auto"
                  width="180"
                  alt=""
                />
                <img
                  src={logoLight}
                  className="l-light  rounded-top "
                  height="auto"
                  width="180"
                  alt=""
                />
              </span>
              {/* <img src={logoLight} height="22" className="logo-dark-mode" alt=""/> */}
              {/* <img src={logoLight} className="l-light mt-2 rounded-top"  width="30" height="20" alt=""/> */}
            </Link>
          )}
        </div>

        {/***** Main Navigation *****/}

        <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
          <ul className={manuClass}>
            <li className={manu === "index" ? "active" : ""}>
              <Link to="/" style={{ color: "#1e45af" }}>
                Home
              </Link>
            </li>
            <li
              className={`${
                [
                  "individual-therapy",
                  "couple-therapy",
                  "social-therapy",
                  "room-and-board",
                  "group-therapy",
                  "home-based-therapy",
                ].includes(manu)
                  ? "active"
                  : ""
              }  has-submenu parent-menu-item`}
            >
              <Link
                to=""
                className="sub-menu-item"
                style={{ color: "#1e45af" }}
              >
                Services
              </Link>
              <ul className="submenu">
                {/* <li className={manu === "index" || "" ? "active" : ""}><Link to="/index" className="sub-menu-item">Index One</Link></li> */}
                <li className={manu === "individual-therapy" ? "active" : ""}>
                  <Link to="/individual-therapy" className="sub-menu-item">
                    Individual Therapy
                  </Link>
                </li>
                <li className={manu === "couple-therapy" ? "active" : ""}>
                  <Link to="/couple-therapy" className="sub-menu-item">
                    Couple Therapy
                  </Link>
                </li>
                <li className={manu === "social-interaction" ? "active" : ""}>
                  <Link to="/social-interaction" className="sub-menu-item">
                    Social Interaction
                  </Link>
                </li>
                <li className={manu === "room-and-board" ? "active" : ""}>
                  <Link to="/room-and-board" className="sub-menu-item">
                    Residential Treatment
                  </Link>
                </li>
                <li className={manu === "group-therapy" ? "active" : ""}>
                  <Link to="/group-therapy" className="sub-menu-item">
                    Group Therapy
                  </Link>
                </li>
                <li className={manu === "family-therapy" ? "active" : ""}>
                  <Link to="/family-therapy" className="sub-menu-item">
                    Family Therapy
                  </Link>
                </li>
                <li
                  className={manu === "psychiatric-medication" ? "active" : ""}
                >
                  <Link to="/psychiatric-medication" className="sub-menu-item">
                    Psychiatric Medication
                  </Link>
                </li>
              </ul>
            </li>
            <li className={manu === "doctor-profile" ? "active" : ""}>
              <Link to="/doctor-profile" style={{ color: "#1e45af" }}>
                Therapist
              </Link>
            </li>
            <li className={manu === "aboutus" ? "active" : ""}>
              <Link to="/aboutus" style={{ color: "#1e45af" }}>
                About US
              </Link>
            </li>

            <li className={manu === "faqs" ? "active" : ""}>
              <Link to="/faqs" style={{ color: "#1e45af" }}>
                FAQ
              </Link>
            </li>
          </ul>
        </div>

        {/***** Login/Sidebar Toggle *****/}

        <div className="headRightBox ">
          <li className="list-inline-item mb-0 ms-1 searchHeadBox">
            <Link
              to="#"
              className="btn btn-icon btn-pills btn-primary"
              onClick={handleShow}
            >
              <FiSearch />
            </Link>
          </li>
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="top"
            style={{ height: "250px" }}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body className="pb-3">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="text-center">
                      <h4>Search now.....</h4>
                      <div className="subcribe-form mt-4">
                        <form>
                          <div className="mb-0">
                            <input
                              type="text"
                              id="help"
                              name="name"
                              className="border rounded-pill"
                              required=""
                              placeholder="Search"
                            />
                            <button
                              type="submit"
                              className="btn btn-pills btn-primary"
                            >
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <Link to="/book-a-consultation" type="button" class="headerBookbtn">
            {" "}
            Book a Consultation{" "}
          </Link>
          {/* <button link="/booking-appointment" type="button" class="headerBookbtn">Book a Consultation</button> */}
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className={`navbar-toggle ${isMenu ? "open" : ""}`}
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/** 
                <ul className="dropdowns list-inline mb-0">
                    <li className="list-inline-item mb-0">
                        <Link to="#"  onClick={handleShowTwo}>
                            <div className="btn btn-icon btn-pills btn-primary"><FiSettings className="fea icon-sm"/></div>
                        </Link>
                    </li> 
                 <Offcanvas show={showTwo} onHide={handleCloseTwo} placement="end">
                        <Offcanvas.Header closeButton className="offcanvas-header p-4 border-bottom">
                            <h5 id="offcanvasRightLabel" className="mb-0">
                                <img src={logoDark1} height="22" className="light-version" alt=""/>
                                <img src={logoLight1} height="22" className="dark-version" alt=""/>

                            </h5>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <div>
                                            <img src={image} alt="" className="w-90 h-auto mx-auto d-block" />
                                        </div>
                                        <h5 className="my-4">Get in touch!</h5>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                        <input name="name" id="name" type="text" className="form-control border rounded" placeholder="First Name :"/>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                        <input name="email" id="email" type="email" className="form-control border rounded" placeholder="Your email :"/>
                                                    </div> 
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Subject</label>
                                                        <input name="subject" id="subject" className="form-control border rounded" placeholder="Your subject :"/>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                        <textarea name="comments" id="comments" rows="4" className="form-control border rounded" placeholder="Your Message :"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                        <div className="offcanvas-footer p-4 border-top text-center">
                            <ul className="list-unstyled social-icon social mb-0">
                                <li className="list-inline-item mb-0"><Link to="https://1.envato.market/doctris-react" target="_blank" className="rounded"><FiShoppingCart className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="https://dribbble.com/shreethemes" target="_blank" className="rounded"><FiDribbble className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="https://www.behance.net/shreethemes" target="_blank" className="rounded"><RiBehanceLine className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="rounded"><FaFacebookF className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="rounded"><FiInstagram className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="https://twitter.com/shreethemes" target="_blank" className="rounded"><FiTwitter className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="mailto:support@shreethemes.in" className="rounded"><LuMail className="align-middle mb-0"/></Link></li>
                                <li className="list-inline-item mb-0"><Link to="https://shreethemes.in" target="_blank" className="rounded"><LuGlobe className="align-middle mb-0"/></Link></li>
                            </ul>
                        </div>
                    </Offcanvas> 
                
                    <li className="list-inline-item mb-0 ms-1">
                        <Link to="#" className="btn btn-icon btn-pills btn-primary" onClick={handleShow} >
                            <FiSearch/>
                        </Link>
                    </li>
                    <Offcanvas show={show} onHide={handleClose} placement="top" style={{height:'250px'}}>
                        <Offcanvas.Header closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="pb-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="text-center">
                                            <h4>Search now.....</h4>
                                            <div className="subcribe-form mt-4">
                                                <form>
                                                    <div className="mb-0">
                                                        <input type="text" id="help" name="name" className="border rounded-pill" required="" placeholder="Search"/>
                                                        <button type="submit" className="btn btn-pills btn-primary">Search</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>

                    <li className="list-inline-item mb-0 ms-1">
                        <div className="dropdown dropdown-primary">
                            <button type="button" className="btn btn-pills btn-soft-primary dropdown-toggle p-0" onClick={()=>setModal(!modal)}><img src={dr1} className="avatar avatar-ex-small rounded-circle" alt=""/></button>
                            <div className={`${modal === true ? 'show' : ''} dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3`} style={{minWidth:"200px"}}>
                                <Link className="dropdown-item d-flex align-items-center text-dark" to="/doctor-profile">
                                    <img src={dr1} className="avatar avatar-md-sm rounded-circle border shadow" alt=""/>
                                    <div className="flex-1 ms-2">
                                        <span className="d-block mb-1">Calvin Carlo</span>
                                        <small className="text-muted">Orthopedic</small>
                                    </div>
                                </Link>
                                <Link className="dropdown-item text-dark mb-2" to="/doctor-dashboard"><span className="mb-0 d-inline-block me-1"><GrDashboard className="align-middle h6 mb-0"/></span> Dashboard</Link>
                                <Link className="dropdown-item text-dark" to="/doctor-profile-setting"><span className="mb-0 d-inline-block me-1"><FiSettings className="align-middle h6 mb-0"/></span> Profile Settings</Link>
                                <div className="dropdown-divider border-top"></div>
                                <Link className="dropdown-item text-dark" to="/login"><span className="mb-0 d-inline-block me-1"><LiaSignOutAltSolid className="align-middle h6 mb-0"/></span> Logout</Link>
                            </div>
                        </div>
                    </li>
                </ul> 
                */}
      </div>
    </header>
  );
}
