import React from "react";
import { Link } from "react-router-dom";
import TransBGImage from "../assets/images/services/ScheduleNoWBG.jpg";

function ScheduleNow() {
  return (
    <>
      <div className="ScheduleWrapp">
        <div
          className="container"
          style={{
            borderRadius: "20px",
            overflow: "hidden",
            maxWidth: "1200px",
            boxShadow: "0px 80px 40px -70px rgba(0, 0, 0, 0.2)",
            background: "rgba(245, 243, 239, 0.7)",
          }}
        >
          <div className="row">
            <div className="col-6 SchLeft">
              <h2>Schedule Now</h2>
              <p>
                Take The First Step Towards Better Mental Health Today! Fill Out
                The Form Below To Sign Up For Our Individual Therapy Program,
                And One Of Our Dedicated Professionals Will Reach Out To You
                Within 24-48 Hours To Schedule Your First Session.
              </p>
              <Link to="/book-a-consultation" className="primaryBtn">
                Book a Consultation{" "}
              </Link>
            </div>
            <div
              className="col-6 SchRight"
              style={{
                backgroundImage: `url(${TransBGImage})`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduleNow;
