import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

const CalendlyWidget = () => {
  useEffect(() => {
    // Load the Calendly script dynamically
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section className="innerHero">
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12">
              <div className="heading-title">
                <h2 className="bannerTitle">Book a Consultation</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="calendlyBox">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Calendly Widget */}
              <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/connect-thriveabundance/kyle-hill"
                  style={{ minWidth: "320px", height: "700px" }} // ✅ Corrected style syntax
                ></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTop />
    </>
  );
};

export default CalendlyWidget;
