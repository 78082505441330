import React from "react";

import bg1 from '../../assets/images/img03.jpg'

import { ctaData } from "../../data/data";

export default function CtaTwo() {
    return (
        // <section className="section" style={{backgroundImage:`url(${bg1})`}}>
        <section className="section bg-no-repeat bg-cover"
            style={{
                backgroundImage: `url(${bg1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh' // Ensures the section takes up the full viewport height
            }}>
            <div className="bg-overlay bg-overlay-dark " style={{ opacity: '0.3' }}></div>
            <div className="container d-flex ">
                <div className="row">
                    <div className="col-12">
                        {ctaData.map((item, index) => {
                            let Icon = item.icon
                            return (
                                <div className="features feature-bg-primary d-flex card flex-row col-12 col-sm-12 col-md-12 col-lg-12 p-4 rounded-md shadow position-relative overflow-hidden "style={{margin:'110px 0px',marginTop:'6rem'}} key={index}>
                                    <Icon className="icons mb-0 text-primary display-1" />
                                    <div className="ms-3 ">
                                        <h5 className="titles">{item.title}</h5>
                                        <p className="text-muted para mb-0">{item.desc}</p>
                                        <p className="text-muted para mb-0" >{item.title1}</p>
                                        <p className="text-muted para mb-0" >{item.desc1}</p>
                                        <p className="text-muted para mb-0" >{item.desc8}</p>
                                        <p className="text-muted para mb-0" >{item.desc2}</p>
                                        <p className="text-muted para mb-0" >{item.desc7}</p>
                                        <p className="text-muted para mb-0" >{item.desc3}</p>
                                        <p className="text-muted para mb-0" >{item.desc4}</p>
                                        <p className="text-muted para mb-0" >{item.desc5}</p>
                                        <p className="text-muted para mb-0" >{item.desc6}</p>
                                    </div>
                                    {/* <div className="big-icon">
                                        <Icon />
                                    </div> */}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}